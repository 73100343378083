import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { SearchSummary } from './SearchSummary';

export function ProgressBar(props: {additionalClass?, step}) {
  let currentStep = props.step -3;
  // use stepper here
  // let steps = [{label:"Confirm"}, {label:"Optional Data"} ,{label:"Finalise"}]
  let steps = ["View Candidates", "Add Description", "Screening Questions", "Finalise"]

  return (
      
      <Stepper className={`${props.additionalClass}`} activeStep={currentStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

  );
}
