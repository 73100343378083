import * as React from 'react';

export function WarningModal(props:{className?:string}) {
  return (
    <div className={props.className} >
          <div className="warning-modal-title">
            Short Notice Warning.
          </div>
          <div className={"flex-center warning-modal-content"}>
              <p>
              You're more likely to get good applicants with a high hourly rate or more notice.
            </p>
          </div>

    </div>
  );
}
