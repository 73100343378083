import * as React from 'react';
import {  Field, useFormikContext } from 'formik';
import { ShiftCell } from './Shift/ShiftCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CategorySearchDropdown } from './CategorySearchDropdown';
import { Category } from '../data/categories';
import { categories } from '../data/data-limber';
import { useState, useRef } from 'react';
import { ShiftField } from './Shift/ShiftField';

export function CategorySearch(props:{isMobile:boolean, scrollRef?}) {
    // let matchedCategories: Category[] =[];
    
    const [matchedCategories, setMatchedCategories] = useState<Category[]>(
        categories
     );
    const [isDropdownEnabled, setIsDropdownEnabled] = useState<boolean>(false); 
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
    

    const { setFieldValue,values } = useFormikContext();
    let currentVals: any = values;
    // setMatchedCategories(categories);
    const searchFieldName:string = "categorySearch";
    const placeHolder:string= "Work type";
    const fieldName:string = "category";
    let modalFieldRef = useRef(null);

    //auto-focus
    
    let getModalOverride = () =>{  
        return isModalOpen;
    }

    let setModalOverride = (val:boolean) =>{
        setIsModalOpen(val)
    }  

    let selectedCategory:string = currentVals.category;
    if(selectedCategory === "undefined"){
        selectedCategory = "";
        setFieldValue("category","");
    }
    // console.log(selectedCategory)


    return(
        <>
        <ShiftCell fieldName="category-search" label="Work type" canTriggerError={false} className={"shift-detail-field dropdown-cell"}>
            {props.isMobile && (
                <span className="worker-search-icon"><FontAwesomeIcon icon={faSearch} /></span>
            )}
            <ShiftField 
            fieldNames={[searchFieldName]} 
            title={"Type of work"}
            isMobile={props.isMobile}
            hiddenField={fieldName}
            scrollRef={props.scrollRef}
            modalRef={modalFieldRef}
            getModalOverride={getModalOverride}
            setModalOverride={setModalOverride}
            >
                    <Field
                    type="text"
                    name={searchFieldName}
                    autoComplete="off"
                    innerRef={modalFieldRef}
                    placeholder={`${ selectedCategory ? selectedCategory : placeHolder} `}
                    className={("dropdown-active")}
                    onChange={(event) => searchCategories(event)}
                    onFocus={()=>onFocus()}
                    onBlur={(event)=>onBlur(event)}
                    />
                    <div className="dropdown-cell">
                        <span className="worker-search-icon"><FontAwesomeIcon icon={faSearch} /></span>
                    </div>
                    <CategorySearchDropdown isEnabled={isDropdownEnabled} matchedCategories={matchedCategories} onSetIsDropdownEnabled={setIsDropdownEnabled} onSelectItem={selectCategory} isMobile={props.isMobile}/>

            </ShiftField>
            <Field type="hidden" name={fieldName}/>
        </ShiftCell>
        </>
    )

    function searchCategories(event) {
        let catgeorySearchInput = event.currentTarget.value;
        setFieldValue(searchFieldName,catgeorySearchInput);
        setMatchedCategories(categories);
        catgeorySearchInput = catgeorySearchInput.trim().toLowerCase();
        let filteredCats: Category[] = categories;
        
        if(catgeorySearchInput){
            filteredCats = filteredCats.filter(function(category){
                return category.displayName.trim().toLowerCase().includes(catgeorySearchInput)
            });
        }
        setMatchedCategories(filteredCats)

    }

    function selectCategory(category: string) {
        // put the selected option into 
        setFieldValue(searchFieldName,category);
        setFieldValue(fieldName,category);
        setIsDropdownEnabled(false);
        setIsModalOpen(false)
        // close and done
    }

    function onBlur(event){
        setTimeout(function(){ 
            setIsDropdownEnabled(false); // only do this if focus is not the input or the dropdown
        }, 150);
    }

    function onFocus(){
        setIsDropdownEnabled(true);
    }

}