import {parse, differenceInMinutes, differenceInDays, startOfDay} from 'date-fns';
import {Config} from '../config';


export class ShiftTimeValue {
  id: number = 0;
  startDate: string = '';
  startDatepicker: Date;

  startTime: string = '';
  startTimepicker: Date;

  endTime: string = '';
  endTimepicker: Date;

  timeRangeTrigger:string = '';
  dateTrigger:string = '';


  constructor(key: number, startTime: string = "", endTime: string = "" ) {
    this.id = key;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}

export class ShiftFormValues {
  type: 'shift' | 'stint' = Config.defaultShiftType === 'stint' ? 'stint' : 'shift';
  categorySearch: string = '';
  category: string = '';
  workers = ''; // this is a number not setting a type avoids a lot of bugs
  hourlyRate: number = 11.44;
  aboveMinWageHourlyRate: number = 0.00
  startDate: string = '';
  endDate: string = '';
  weeklyAverageHours: number;
  googlePlace: google.maps.places.PlaceResult = null;
  isGroupShift: boolean = false;
  isLoadedLocation: boolean = false;
  isVariableRate: boolean = false;
  shifts: ShiftTimeValue[] = [new ShiftTimeValue(0)];
  constructor() {
  }



  getStartDate(index: number = 0): Date {
    if (this.type === 'stint') {
      return parse(`${this.startDate} 02:00`, 'yyyy-MM-dd HH:mm', new Date());
    }
    let startDateText:string = "";
    let startTime:string = "";
    if(this.shifts[index]){
      startDateText = this.shifts[index].startDate;
      startTime = this.shifts[index].startTime;
    }
    let startDate = this.produceValidDate(startDateText);
    return parse(`${startDate} ${startTime}`, 'yyyy-MM-dd HH:mm', new Date());
  }

  getEndDate(index: number = 0): Date {
    if (this.type === 'stint') {
      return parse(`${this.endDate} 16:00`, 'yyyy-MM-dd HH:mm', new Date());
    }
    let startDateText:string = "";
    let endTime:string = "";
    if(this.shifts[index]){
      startDateText = this.shifts[index].startDate;
      endTime = this.shifts[index].endTime;      
    }
    let startDate = this.produceValidDate(startDateText);
    return parse(`${startDate} ${endTime}`, 'yyyy-MM-dd HH:mm', new Date());
  }

  produceValidDate(date: string): string{
    if(date === ""){
      let today:Date = new Date();
      date = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
    }
    return date
  }

  getDurationInHours(index: number = 0): number {
    if (this.type === 'stint') {
      const days = differenceInDays(startOfDay(this.getEndDate()), startOfDay(this.getStartDate())) + 1;

      if (days <= 0) {
        return 0;
      }

      return (days / 7) * this.weeklyAverageHours;
    }

    let difference = differenceInMinutes(this.getEndDate(index), this.getStartDate(index)) / 60;
    if(difference < 0){
      
      difference = 24 + difference;
    }

    return difference;
  }

  getTotalDurationHours():number{
    let output:number = 0;
    this.shifts.map((shift) =>{
      output = output + this.getDurationInHours(shift.id)
    })
    return output;
  }

  isMoreThanOneDay(index: number = 0): boolean{
    let difference = differenceInMinutes(this.getEndDate(), this.getStartDate()) / 60;
    return difference < 0;
  }
}
