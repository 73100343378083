import * as React from 'react';
import { fixNumber } from '../utils';
import { useFormikContext } from 'formik';
import { InputControlButton } from '../Components/InputControlButton';
import { useEffect } from 'react';

export function DiscreetNumberModal(props:{children, fieldName}) {
  const { setFieldValue, values } = useFormikContext();
  const currentFieldName: string = props.fieldName;
  const minimum: number = 1;
  let isAtMinimum:boolean = values[currentFieldName] <= minimum;

  const updateRate = function(amount:number) {
    let newValue:number = 1;

    newValue = fixNumber(values[currentFieldName]+amount);
    
    if(newValue < minimum){
      newValue = minimum;
    }
    setFieldValue(currentFieldName,newValue)
  }

  useEffect(()=>{
    if(values[currentFieldName] === "" || values[currentFieldName] === undefined){
      setFieldValue(currentFieldName,minimum)
    }
  })


  return (
    <div className="worker-contentModal">
        <div className="hourly-modal-content">
            <InputControlButton isDisabled={isAtMinimum} symbol={"-"} amount={-1} handler={updateRate}/>
            <div className="content-field">
              <div className={`worker-input-field`}>{props.children}</div>
            </div>
            <InputControlButton isDisabled={false} symbol={"+"} amount={1} handler={updateRate}/>
        </div>
    </div>
  );
}