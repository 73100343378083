import React from 'react';
import Icon from '@material-ui/core/Icon';
import StarsIcon from '@material-ui/icons/Stars';

export function WorkerRating(props:{rating, count?}) {
    const {rating,count} = props; 
    return (
        <div className="rating-container">
            <div className="star-container">
                <StarsIcon className={`material-icons limber-rating-star ${rating > 0 ? "limber-rating-star-filled" :""}`}/>
                <StarsIcon className={`material-icons limber-rating-star ${rating > 1 ? "limber-rating-star-filled" :""}`}/>
                <StarsIcon className={`material-icons limber-rating-star ${rating > 2 ? "limber-rating-star-filled" :""}`}/>
            </div>
            {count !== undefined && (
                <span>
                    {count}
                </span>
            )}
        </div>


    )
}