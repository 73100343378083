import * as React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { format } from 'date-fns';

export function DateRange(props: {children, startDateFieldName:string, endDateFieldName:string}) {
    const { setFieldValue, values } = useFormikContext();
    // set it to the current selected date
    let startingVal:string = values[props.startDateFieldName];
    let endingVal:string = values[props.endDateFieldName];
    let startingDate:Date = new Date();
    let endingDate:Date = null;

    if(startingVal){
        startingDate = new Date(startingVal);
    }
    if(endingVal){
        endingDate = new Date(endingVal)
    }

    const [startDate, setStartDate] = useState(startingDate);
    const [endDate, setEndDate] = useState(endingDate);
    
    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        
        let startValue:string =format(start,"yyyy-MM-dd");
        let endValue:string =format(end,"yyyy-MM-dd");

        setFieldValue(props.startDateFieldName,startValue);
        setFieldValue(props.endDateFieldName,endValue);
      };
    return(
        <>
            <DatePicker
            name={`stint-picker`}
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            minDate={new Date()}
            disabledKeyboardNavigation
            selectsRange
            inline
            />
            {props.children}
        </>
    )
}
