import * as React from 'react';
import { useFormikContext, Field } from 'formik';
import { VariablePayRadioGroup } from '../Components/RadioGroups/VariablePayRadioGroup';
import { fixNumber } from '../utils';
import { useState, cloneElement } from 'react';
import { InputControlButton } from '../Components/InputControlButton';
import { MinimumWageTable } from '../Components/MinimumWageTable';

export function HourlyRateModal(props:{children, altChildren, minimumWage}) {
  const { setFieldValue, values } = useFormikContext();
  let isVariableRate:boolean =  values["isVariableRatePrelim"];
  let currentFieldName:string = isVariableRate ? "aboveMinWageHourlyRate" : "hourlyRate";

  // extarct the concept of a display field
  let minimum:number = isVariableRate ? 0 : 11.44;
  let isAtMinimum:boolean = values[currentFieldName] <= minimum;
  let currencyValue:string  = values[currentFieldName].toLocaleString('en-GB', { minimumFractionDigits: 2 });
  const [isInputMode, setIsInputMode] = useState<boolean>(true);

  const updateRate = function(amount:number){
    let newValue:number = 0;
    setIsInputMode(false);
    if(values[currentFieldName] === minimum && minimum===11.44 && amount > 0){
      newValue = 11.44;
    }else{
      newValue = fixNumber(values[currentFieldName]+amount);
    }
    if(newValue < minimum){
      newValue = minimum;
    }
    setFieldValue(currentFieldName,newValue)
  }


  let varVisibileCurrencyField = <Field type="text" name={"currency-amount-var"} value={currencyValue} onClick={()=>{changeMode(true)}} className="variable-hourly-rate"/>
  let fixedVisibileCurrencyField = <Field type="text" name={"currency-amount-fixed"} value={currencyValue} onClick={()=>{changeMode(true)}} className="fixed-hourly-rate"/>
  return (
    <>
      <div><VariablePayRadioGroup fixedTitle={"Fixed rate"}  variableTitle={"Variable rate"} isRadio={false} values={values} setFieldValue={setFieldValue}/></div>
      {isVariableRate && (
        // i can be my own component especially
        <>
          <div className="hourly-modal-content">
            <InputControlButton isDisabled={isAtMinimum} symbol={"-"} amount={-0.25} handler={updateRate}/>
              <div className="content-field">£
                <span className={`rate-display-field ${isInputMode? "worker-hidden" : ""}`}>{varVisibileCurrencyField}</span>
                <span  className={`rate-input-field ${isInputMode? "" : "worker-hidden"}`}>{props.altChildren}</span>
              </div>
            <InputControlButton isDisabled={false} symbol={"+"} amount={0.25} handler={updateRate}/>
          </div>
          <div className="wage-label">above minimum wage</div>
          <MinimumWageTable minimumWage={props.minimumWage}/>
        </>
      )}
      {!isVariableRate && (
        <div className="hourly-modal-content">
            <InputControlButton isDisabled={isAtMinimum} symbol={"-"} amount={-0.25} handler={updateRate}/>
            <div className="content-field">£
              <span className={`rate-display-field ${isInputMode? "worker-hidden" : ""}`}>{fixedVisibileCurrencyField}</span>
              <span className={`rate-input-field ${isInputMode? "" : "worker-hidden"}`}>{props.children}</span>
            </div>
            <InputControlButton isDisabled={false} symbol={"+"} amount={0.25} handler={updateRate}/>
        </div>
      )}

    </>
  );

  function changeMode(isEnabled){
    setIsInputMode(isEnabled)
  }
}
