import * as React from 'react';
import { VariablePayRadioGroup } from '../Components/RadioGroups/VariablePayRadioGroup';
import { useFormikContext } from 'formik';
import { MinimumWageTable } from '../Components/MinimumWageTable';

export function RateModal(props:{children, minimumWage}) {
    const { setFieldValue, values } = useFormikContext();


  return (
    <div className="" >
          <div className="desktop-modal-title">
            Select pay type:
          </div>
        <div className="flex-center">
          <VariablePayRadioGroup fixedTitle={"Fixed"}  variableTitle={"Variable"} setFieldValue={setFieldValue} isRadio={true} values={values} altFieldName={'isVariableRate'}/>
        </div>
        <div className="desktop-modal-input-wrapper flex-center">
          <span className="">£</span>
          {props.children}
        </div>
        {values["isVariableRate"] && (
          <>
            <div className="desktop-modal-text">
            Above the minimum wage
            </div>
            <MinimumWageTable minimumWage={props.minimumWage}/>
          </>
        )}
    </div>
  );
}
