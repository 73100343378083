import React, { useEffect } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck,faLock} from '@fortawesome/free-solid-svg-icons';
import {Config} from './config';
import CountUp from "react-countup";
import {ShiftFormValues} from './JobPosting/ShiftFormValues';
import LinkedInTag from 'react-linkedin-insight';
import { WorkerProfile } from './Components/WorkerProfile';
import { DisplayWorker, estimateActiveWorkers, topWorkers } from './ApiClient';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useViewport } from './Hooks/viewport';
import { SearchSummary } from './Components/SearchSummary';

export function Step3Confirm(props: {count: number, topWorkerList: DisplayWorker[], values: ShiftFormValues, setTopWorkerList, onGotoLogin, backComponent?}) {
  // when the venue is in london get current active workers for london this week same for bristol 50km
  const { width } = useViewport();
  // get break point
  let slidesToShow = 3;
  if(width <= 1000 ) {
    slidesToShow = 2;
  }  
  if (width <= 700) {
    slidesToShow = 1.075;
  }
  //  450 remove buttons and enable swipe and add auto-play
  //  props count get accurate estimate per day for area depending on location and current total

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: true,
    centerMode: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1
  };
  let selectTopworkers = props.topWorkerList.slice(0,10);
  // console.log(props.backComponent)
  return (
    <div className="hirer-onboarding-signup ">
      <div className="hirer-summary">
        <div className="hero-area">
          <div className="results-count">
            <div className="heading">
              <CountUp className="modal-counter" end={props.count}/>
              <strong>
              available {props.values.category.toLocaleLowerCase()} staff
            </strong>
              <SearchSummary shiftData={props.values}/>

            </div>
            <p className="subheading">
              Click next to reach candidates like these.
            </p>
          </div>
          <div className="text-center">    
                {/* window.lintrk('track', { conversion_id: 6144913 }); */}
                <button className="initial_summary_tracking button button-primary text-center" type="submit"  onClick={() => {
                    LinkedInTag.init("2735777", 'dc', false);
                    LinkedInTag.track("6144913");
                    props.onGotoLogin();
                }}>
                Next               
                </button>
            </div>
        </div>

        <div className="content-container triple-lock-container desktop-item">
          <h3 className="modal-section-title"><FontAwesomeIcon icon={faLock} /> Triple lock guarantee</h3>
          <ul className="features text-center">
            <li>
              <FontAwesomeIcon icon={faCheck} /> All workers on limber have the right to work in the UK
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> {Config.platformName} handles all payroll and pension obligations
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Employment liability insurance included as standard
            </li>
          </ul>
          <p className="text-right faq-section"><strong>Got a question?</strong> <a href="https://limber.work/support/employer-faq/">View Employer FAQs</a></p>
        </div>
            <div className="slider-container"> 
              <Slider {...settings}>
                {selectTopworkers.map((topWorker,index) => {
                    return (<WorkerProfile topWorker={topWorker}/>);
                })}
              </Slider>
            </div>
        <div className="content-container triple-lock-container mobile-item">
          <h3 className="modal-section-title"><FontAwesomeIcon icon={faLock} /> Triple lock guarantee</h3>
          <ul className="features">
            <li>
              <FontAwesomeIcon icon={faCheck} /> All workers on limber have the right to work in the UK
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> {Config.platformName} handles all payroll and pension obligations
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Employment liability insurance included as standard
            </li>
          </ul>
          <p className="text-right"><strong>Got a question?</strong> <a href="https://limber.work/support/employer-faq/">View Employer FAQs</a></p>
        </div>
        <div className="personal-data-container">
          <h3 className="modal-section-title">Personal data</h3>
          <p>
            We take personal data seriously, which is why you can't see applicants' photographs. 
            Once your shift is live on limber, you'll receive applicants and you can review their full profile.
          </p>

          <p>View our full hirer terms and conditions <a href="https://limber.work/terms-conditions/">here</a>.</p>
        </div>

        </div>
    </div>
  );
}
