
import * as React from 'react';
import { currency } from '../Summary/CostBreakdown';
import { useFormikContext } from 'formik';

export function MinimumWageTable(props:{minimumWage}) {
    const {  values } = useFormikContext();


    return(
        <div className={"min-wage-table"}>
        <div className="min-wage-table-title">Rates: </div>
        <dl>
          <dt>Aged 18-20:</dt>
          <dd>{currency(props.minimumWage[0].rate + values["aboveMinWageHourlyRate"])}</dd>

        </dl>
        <dl>
          <dt>Aged 21-22:</dt>
          <dd>{currency(props.minimumWage[1].rate + values["aboveMinWageHourlyRate"])}</dd>

        </dl>
        <dl>
        <dt>Aged 23+:</dt>
          <dd>{currency(props.minimumWage[2].rate + values["aboveMinWageHourlyRate"])}</dd>
        </dl>
      </div>
        )
}