import {Field,FieldArray, Formik, Form} from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import {CostBreakdown} from '../Summary/CostBreakdown';
import {ShiftFormValues} from './ShiftFormValues';
import {Config} from '../config';
import {ShiftRow} from '../Components/Shift/ShiftRow';

import { AdditionalShiftControls } from './AdditionalShiftControls';
import { ShiftCell } from '../Components/Shift/ShiftCell';
import {getShiftFormValidationSchema} from './ShiftFormSchema'
import { DurationRadioGroup } from '../Components/RadioGroups/DurationRadioGroup';
import LinkedInTag from 'react-linkedin-insight';

import {
  isSafari,
  isMobile
} from "react-device-detect";
import { DateField } from '../Components/Shift/DateField';
import { CategorySearch } from '../Components/CatgeorySearch';
import { Autocomplete } from '../Components/Shift/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ShiftField } from '../Components/Shift/ShiftField';
import { useViewport } from '../Hooks/viewport';
import { DiscreetNumberModal } from '../MobileModals/DiscreetNumberModal';
import { HourlyRateModal } from '../MobileModals/HourlyRateModal';
import { DateRangeModal } from '../MobileModals/DateRangeModal';
import { DateRange } from '../Components/Shift/DateRange';
import { useRef } from 'react';
import { RateModal } from '../DesktopModals/RateModal';
import { ShiftLabel } from '../Components/Shift/ShiftLabel';


export interface SignupformProps {
  onSubmit(values: ShiftFormValues): Promise<void>;
  updateMinimumWage;
  minimumWageSet;
  minimumWage;
  forceAltTimeInput?:boolean;
}

export class CategorySetting {
  categoryGroup:string="";
  category:string="";

  constructor( category: string = null, categoryGroup:string= null) {
    this.category = category;
    this.categoryGroup = categoryGroup
  }
}

export function Step1ShiftForm(props: SignupformProps) {

  let isAltEnabled:boolean =  false || (isSafari && !isMobile);
  let schema: Yup.ObjectSchema = getShiftFormValidationSchema(props.minimumWage);
  const { width } = useViewport();
  let isMobileBySize: boolean = width <= 500;
  let isMobileView:boolean = isMobile && isMobileBySize
  
  // have special functions   for mobile which trigger modals
  const workerFieldName:string ="workers";
  const workerPlaceHolder:string = isMobileView ? "No. of people" : "-";

  const hourlyFieldName:string ="hourlyRate";
  const hourlyPlaceHolder:string = isMobileView ? "Hourly rate" :"-";

  const variableHourlyFieldName:string ="aboveMinWageHourlyRate";
  const variableHourlyPlaceHolder:string = isMobileView ? "Hourly rate" :"-";

  const startDateFieldName:string ="startDate";
  const startDatePlaceHolder:string = isMobileView ? "Start Date" : "dd/mm/yyyy";

  const endDateFieldName:string ="endDate";
  const endDatePlaceHolder:string = isMobileView ? "End Date" : "dd/mm/yyyy";

  const weeklyAverageHoursFieldName:string = "weeklyAverageHours";
  const weeklyAverageHoursPlaceHolder:string = isMobileView ? "Hours per week" :"-";

  const dateRangeTrigger:string = "dateRangeTrigger";
  const dateRangeTitle:string = "Start - End date";
  let autoCompleteTrigger = <Field type="text" className="dropdown-active" placeHolder={"Start typing your business"} name={"autoCompleteTrigger"}/>
  let dateRange = <Field  type="text" className="first-cell" placeHolder={dateRangeTitle} name={dateRangeTrigger}/>

  let modalVariableFieldRef = useRef(null);
  let modalFixedFieldRef = useRef(null);
  let modalWorkerRef = useRef(null);
  let autoCompleteRef = useRef(null);
  let weeklyAverageHoursRef = useRef(null);
  let formRef = useRef(null);


  let variableHourlyField = <Field type="number" innerRef={modalVariableFieldRef} name={variableHourlyFieldName} min={0} step="1" placeholder={hourlyPlaceHolder} className="variable-hourly-rate"/>
  let fixedHourlyField = <Field type="number" innerRef={modalFixedFieldRef} name={hourlyFieldName} min={props.minimumWage[2].rate} step="1" placeholder={variableHourlyPlaceHolder} className="fixed-hourly-rate"/>
  let currentHourlyRef = modalFixedFieldRef;
  let customWorkerModalContent = <DiscreetNumberModal children={<></>} fieldName={workerFieldName}/>
  let customWeeklyHoursModalContent = <DiscreetNumberModal children={<></>} fieldName={weeklyAverageHoursFieldName}/>

  let currentHourlyField = fixedHourlyField;
  let fixedHourlyLabel = <ShiftLabel fieldName={hourlyFieldName} title={"Hourly rate"} currencyElement="£" titleClassName="desktop-hidden"/>
  let variableRateTitle:string  = isMobileView ? "Above minimum wage":"Min. Wage +";
  let variableHourlyLabel = <ShiftLabel fieldName={variableHourlyFieldName} title={variableRateTitle} currencyElement="£" subTitleClassName={"variable-min-wage"}/>
  let hourlyLabel;
  let customHourlyModalContent;


  let customDateRangeModalContent =<DateRangeModal children={<></>}/>

  

  return (
    <Formik
    // better validation
      // enableReinitialize={true}
      initialValues={new ShiftFormValues()}
      validationSchema= {schema}
      onSubmit={async (values, {setSubmitting}) => {
        try {
          // window.lintrk('track', { conversion_id: 6144905 }); // linkedin tracking
          LinkedInTag.init("2735777", 'dc', false);
          LinkedInTag.track("6144905");
          await props.onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({isSubmitting, values, setFieldValue, setFieldTouched, touched, errors, isValid}) => (
        <Form noValidate className="hirer-onboarding-form hirer-onboarding-form-color">
          <Field name="isLoadedLocation" type="checkbox" className="worker-hidden"/>
          {
            <span className="worker-hidden">
            { currentHourlyField =  values.isVariableRate ? variableHourlyField :fixedHourlyField }

            { hourlyLabel =  values.isVariableRate ? variableHourlyLabel :fixedHourlyLabel }
            { customHourlyModalContent = isMobileView ? <HourlyRateModal
            children={fixedHourlyField}
            altChildren={variableHourlyField}
            minimumWage={props.minimumWage}
            /> : <RateModal 
            children={<></>}
            minimumWage={props.minimumWage}
            /> }

            </span>
          }

          {/* contain this as a component */}
          <div ref={formRef} className="form-container">
            <div className={`input-group shift-details ${values.type === "stint" ? "stint-form flexWrap" : "flexWrap"}`}>
            <ShiftCell fieldName={"googlePlace"} label={"Business name"} canTriggerError={(errors.googlePlace && touched.googlePlace) as boolean} className={"shift-detail-field dropdown-cell first-shift-field"}> 
              {isMobileView && (
                <span className="worker-search-icon"><FontAwesomeIcon icon={faSearch} /></span>
              )}
              <ShiftField fieldNames={["none"]} title={"Location"} isMobile={isMobileView} customMobileFieldContent={autoCompleteTrigger} modalRef={autoCompleteRef} scrollRef={formRef} >
                  <Autocomplete 
                  name="googlePlace"
                  innerRef={autoCompleteRef}
                  isMobile={isMobileView}
                  value={getPlaceName(values.googlePlace)} 
                  onSelect={(place) =>  {setFieldValue('googlePlace', place ); setFieldValue('autoCompleteTrigger',getPlaceName(place)) }} 
                  onBlur={() => setFieldTouched('googlePlace')} />
                  <div className="dropdown-cell">
                    <span className="worker-search-icon"><FontAwesomeIcon icon={faSearch} /></span>
                  </div> 
              </ShiftField>
            </ShiftCell>
            <CategorySearch isMobile={isMobileView} scrollRef={formRef}/>

            <div className="flex-wrap second-section">
            <ShiftCell fieldName={workerFieldName} label={"No. of people"} canTriggerError={(errors.workers && touched.workers) as boolean} className={"overlay-container shift-detail-field worker-field"}> 
            <span className="desktop-hidden first-label-title">{ `${values[workerFieldName] ? "No. of people":""}`}</span>
              <ShiftField modalRef={modalWorkerRef} fieldNames={[workerFieldName]} title={"No. of people"} isMobile={isMobileView}  customModalContent={customWorkerModalContent}  scrollRef={formRef}>
                <Field type="number" innerRef={modalWorkerRef} name={workerFieldName} min="1" step="1" placeholder={workerPlaceHolder}  />
              </ShiftField>
            </ShiftCell>

              <ShiftCell fieldName={hourlyFieldName} label={"Hourly rate"}  canTriggerError={(values.isVariableRate && errors.aboveMinWageHourlyRate && touched.aboveMinWageHourlyRate)
                  || (!values.isVariableRate && errors.hourlyRate && touched.hourlyRate)}  className={`overlay-container shift-detail-field wage-field final-shift-field final-cell`}> 
                {hourlyLabel}
                <ShiftField hasModalChild={true} fieldNames={[hourlyFieldName]} title={"Hourly rate"}  modalRef={currentHourlyRef} isMobile={isMobileView} customModalContent={customHourlyModalContent} customMobileFieldContent={currentHourlyField} hasDesktopModal={true}  scrollRef={formRef}>
                  {currentHourlyField}
                </ShiftField>
              </ShiftCell>

            </div>
             </div>
             {/* <DurationRadioGroup values={values} setFieldValue={setFieldValue}/> */}
             {values.type === 'stint' && (
                <div className={`shift-input-group first-shift-row stint-row ${isSafari? "ios-shift-input-group" : ""}`}>

                  {isMobileView && (
                    <>
                      <ShiftCell fieldName={startDateFieldName} label={dateRangeTitle}  canTriggerError={(errors.startDate && touched.startDate) as boolean}  isAltFieldEnabled={isAltEnabled} errors={errors} className="overlay-container first-shift-field"> 
                      <span className="desktop-hidden first-label-title">{ `${values[startDateFieldName] ? dateRangeTitle:""}`}</span>
                        <ShiftField fieldNames={[startDateFieldName,endDateFieldName]} title={dateRangeTitle}  isMobile={isMobileView} customMobileFieldContent={dateRange} hiddenField={dateRangeTrigger} customModalContent={customDateRangeModalContent}  scrollRef={formRef}>
                          <DateRange startDateFieldName={startDateFieldName} endDateFieldName={endDateFieldName}>
                          <Field type="text" className="worker-hidden" name={startDateFieldName} onChange={()=>{}}/>
                          <Field type="text" className="worker-hidden" name={endDateFieldName} onChange={()=>{}}/>
                          </DateRange>
                        </ShiftField>
                      </ShiftCell>
                    </>
                  )}
                  {!isMobileView && (
                    <>                  
                      <ShiftCell fieldName={startDateFieldName} label={"Start Date"}  canTriggerError={(errors.startDate && touched.startDate) as boolean}  isAltFieldEnabled={isAltEnabled} errors={errors} className="first-shift-field"> 
                          <DateField 
                          isAltFieldEnabled={isAltEnabled}
                          placeHolder={startDatePlaceHolder}
                          className="ios-input-fields"
                          safariTimeSelect={[startDateFieldName]}
                          name={startDateFieldName}
                          updateMinimumWage={props.updateMinimumWage}
                          minimumWageSet={props.minimumWageSet}
                          />
                      </ShiftCell>

                      <ShiftCell fieldName={endDateFieldName} label={"End Date"}  canTriggerError={(errors.endDate && touched.endDate) as boolean}  isAltFieldEnabled={isAltEnabled}  errors={errors}  className="flex-grow-2" > 
                          <DateField 
                          isAltFieldEnabled={isAltEnabled}
                          placeHolder={endDatePlaceHolder}
                          className="ios-input-fields"
                          safariTimeSelect={[endDateFieldName]}
                          name={endDateFieldName}
                          updateMinimumWage={props.updateMinimumWage}
                          minimumWageSet={props.minimumWageSet}
                          />
                      </ShiftCell>
                    </>
                  )}

                  <ShiftCell fieldName={weeklyAverageHoursFieldName}  label={"Weekly hours"}  canTriggerError={(errors.weeklyAverageHours && touched.weeklyAverageHours) as boolean} className="overlay-container weekly-hours-field final-cell"> 
                  <span className="desktop-hidden min-wage-label-title">{ `${values[weeklyAverageHoursFieldName] ? "Hours per week":""}`}</span>
                    <ShiftField title={"Estimated hours per week"} modalRef={weeklyAverageHoursRef} fieldNames={[weeklyAverageHoursFieldName]} isMobile={isMobileView} customModalContent={customWeeklyHoursModalContent}  scrollRef={formRef}>
                      <Field type="number" innerRef={weeklyAverageHoursRef} name={weeklyAverageHoursFieldName} min="1" step="1" placeholder={weeklyAverageHoursPlaceHolder} />
                    </ShiftField>
                  </ShiftCell>
                </div>
              )}
              {/* if mobile size every ew on is its own new row */}
              {values.type === 'shift' && (
                <>
                  <FieldArray 
                    name="shifts"
                    render={({ remove }) => (                      
                      <>
                        {values.shifts.length > 0 && values.shifts.map((shift,index)=>(
                            <ShiftRow 
                            isAltEnabled={isAltEnabled}
                            key={index}
                            index={index}
                            errors={errors}
                            touched={touched}
                            remove={remove}
                            scrollRef={formRef}
                            updateMinimumWage={props.updateMinimumWage}
                            minimumWageSet={props.minimumWageSet}
                            /> 
                        ))}  
                      </>

                    )}
                  />
                </>
              )}
            <AdditionalShiftControls values={values} setFieldValue={setFieldValue} />
          </div>

          <CostBreakdown values={values}  isOpen={false}/>
          <div className="shift-submit-section">
            {/* window.lintrk('track', { conversion_id: 6144905 } */}
            <button className="essential_details_tracking button button-primary shift-submit" type="submit" disabled={isSubmitting || !isValid}>
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );

  function getPlaceName(googlePlace):string{
    let location:string ="";
    if( googlePlace && googlePlace.address_components.length >0){
      location = googlePlace.formatted_address;
      location = googlePlace.name +", "+ googlePlace.address_components[0].long_name + ", " + googlePlace.address_components[1].long_name + ", " +googlePlace.address_components[2].long_name
    }
    return location
  }
}
