import React from 'react';
import {Config} from './config';
// @ts-ignore
import loadingImages from './images/loading-*.gif';

export function Step2Searching() {
  return (
    <div className="hirer-onboarding-searching text-center">
      <img src={loadingImages[Config.platformName] || Object.values(loadingImages)[0]} />

    <div className="search-container">
      <h2 className="mt2">Searching...</h2>
      <p className="mt2">
        The {Config.platformName} app is doing it's thing and searching for skilled people in your area.
      </p>
    </div>

    </div>
  );
}
