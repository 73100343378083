import React from 'react';
import { ShiftFormValues } from '../JobPosting/ShiftFormValues';
import format from 'date-fns/format';


export function SearchSummary(props: {shiftData:ShiftFormValues }) {
  return (
    <>
    {props.shiftData.type ==="shift" && (
        <div className="search-summary-container">
            {props.shiftData.shifts.map((shift) => {
                return (
                <>
                    <span className="search-summary time-summary">{shift.startTime} - {shift.endTime}</span> on <span className="search-summary date-summary">{format(new Date(shift.startDate),"EEEE do MMMM yyyy")}</span>
                </>)
            })}
        </div>
    )}
    {props.shiftData.type ==="stint" && (
        <div className="search-summary-stint-container">
            {props.shiftData.shifts.map((shift) => {
                return (
                <>
                    <span className="search-summary time-summary">{
                    format(new Date(props.shiftData.startDate),"dd MMMM yyyy")} - {format(new Date(props.shiftData.endDate),"EEEE Do MMMM yyyy")}
                    </span> | 
                    <span className="search-summary date-summary">{props.shiftData.weeklyAverageHours} hours</span> | 
                    <span className="search-summary rate-summary">£{props.shiftData.hourlyRate.toString()}</span>
                </>)
            })}
        </div>
    )}
    </>
  );
}
