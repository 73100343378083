import React from 'react';
import { WorkerRating } from './WorkerRating';
import { ReliabilityRank } from './ReliabilityRank';
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DisplayWorker } from '../ApiClient';


export function WorkerProfile(props: {topWorker: DisplayWorker}) {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [isExpandedIntro, setExpandedIntro] = React.useState<boolean>(false);
    const [showIntro, setShowIntro]  = React.useState<string>("hidden-intro");
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    const handleChangeIntro = () => () => {
        setExpandedIntro(!isExpandedIntro);
        setShowIntro(isExpandedIntro ? "hidden-intro" : "");
    };
    let threeStarRatingCount = 0;
    let twoStarRatingCount = 0;
    let oneStarRatingCount = 0;
    props.topWorker.ratings.map((rating) => {
        if (rating.rating === 1) {
            oneStarRatingCount++;
        } else if(rating.rating === 2) {
            twoStarRatingCount++;
        } else if(rating.rating === 3) {
            threeStarRatingCount++;
        }
    });

    let mostRecentRatings = props.topWorker.ratings.slice(0,10);

    return (
        // <Grid item md={4} sm={6} xs={12}>
            <div className="content-container worker-container">
                <div className="profile-row">
                    <div className="profile-avatar-container">
                        <img className="profile-blur" src={props.topWorker.avatarUrl}/>
                    </div>
                    <div className="profile-details"><span className="profile-firstName">{props.topWorker.firstName}</span>
                        <div className="last-seen-row">
                            <div className="profile-label">Last seen: </div>
                            <div className="profile-value">{props.topWorker.lastActive}</div>
                        </div>                
                    </div>
                </div>

                <div className="intro-row" onClick={handleChangeIntro()}>
                    <div className="intro-label">Intro: </div>
                            <div className={`intro-value ${isExpandedIntro ? "hidden-intro" : ""}`}>
                                {props.topWorker.introduction}
                            </div>

                            <div className={`intro-full ${!isExpandedIntro ? "hidden-intro" : ""}`}>
                                {props.topWorker.introduction}
                            </div>

                </div>
                <Accordion expanded={expanded === 'ratings'} onChange={handleChange('ratings')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Grid
                        container
                        spacing={0}
                        alignContent={"center"}
                        justify="center">
                            <Grid item lg={3} md={2} sm={2} xs={12}>
                                <h3 className="modal-title">Ratings</h3> 
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <WorkerRating rating={3} count={threeStarRatingCount}/>
                            </Grid>
                            <Grid item lg={3} md={3}  sm={3} xs={3}>
                                <WorkerRating rating={2} count={twoStarRatingCount}/>
                            </Grid>
                            <Grid item lg={3} md={3}  sm={3} xs={3}>
                                <WorkerRating rating={1} count={oneStarRatingCount}/>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="venue-container">
                        {mostRecentRatings.map((rating) => {
                           return (
                            <div className="venue-item">
                                <Grid
                                container
                                spacing={0}
                                alignContent={"center"}
                                justify="space-between">
                                    <Grid item className="venue-name" xs={3} sm={2} lg={4} md={4}>
                                        {rating.venue.name}
                                    </Grid>
                                    <Grid item className="venue-category" xs={3} sm={4} lg={4} md={4}>
                                        <div className="profile-category category-bar">{rating.category}</div>
                                    </Grid>
                                    <Grid item className="venue-category" xs={3} sm={2} lg={4} md={4}>
                                        <WorkerRating rating={rating.rating}/>
                                    </Grid>
                                </Grid>
                            </div>
                            );
                        })}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'reliability'} onChange={handleChange('reliability')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Grid
                        container
                        spacing={0}
                        alignContent={"center"}
                        justify="center">
                            <Grid item md={4} sm={4} xs={12}>
                                <h3 className="modal-title">Reliability</h3>
                            </Grid>
                            <Grid item md={8} sm={8} xs={12}>
                                <ReliabilityRank rankTitle={props.topWorker.reliabilityRankDisplay} rank={props.topWorker.reliabilityRank} />
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                        container
                        spacing={4}
                        alignContent={"center"}
                        justify="space-between">
                            <Grid item md={4}>
                                <div>Shifts completed</div>
                                <div>{props.topWorker.shiftsCompletedCount}</div>
                            </Grid>
                            <Grid item md={4}>
                                <div>Late cancellations</div>
                                <div>{props.topWorker.shiftsCancelledCount}</div>
                            </Grid>
                            <Grid item md={4}>
                                <div>No shows</div>
                                <div>{props.topWorker.shiftsAbsentCount}</div>
                            </Grid>

                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        // </Grid>
  );
}
