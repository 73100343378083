import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Config} from './config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {signin} from './ApiClient';
import {Alert} from './Components/Alert';
import {ga} from './utils';
import ReactModal from 'react-modal';

export class SigninFormValues {
  email: string = '';
  password: string = '';

  static test() {
    return Object.assign(new this(), {
      email: `clement+hirer@limber.work`,
      password: 'asdfasdf',
    });
  }
}

export interface SigninProps {
  onGotoSignup();
  onSignin(something: any);
  backComponent?: any;
}

export function Step7Signin(props: SigninProps) {
  const [error, setError] = useState('');
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  async function submit(values: SigninFormValues, {setSubmitting}): Promise<void> {
    try {
      setError('');

      const session = await signin(values);

      props.onSignin(session);
    } catch (err) {
      ga('Onboarding', 'Signin error', err.message);
      setError('Your email or your password is invalid.');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="hirer-onboarding-signup">
      <div className="hirer-form-container">
      <span className="mini-modal">
          {props.backComponent}
      </span> 
      <div className="header-section">
        <h2 className="mt0 mb0 modal-section-title">Existing members</h2>
        <div className="subheading">Log in to post your work</div>
      </div>
      <Formik
        initialValues={document.location.hash.includes('test') ? SigninFormValues.test() : new SigninFormValues()}
        validationSchema={Yup.object().shape({
          email: Yup.string().required('Required').email('Must be a valid email'),
          password: Yup.string().required('Required').min(8, 'Must be at least 8 characters'),
        })}
        onSubmit={submit}
      >
        {({isSubmitting, errors, touched}) => (
          <Form className="hirer-onboarding-signup-form">
            {error && <Alert message={error} />}

            <div className="text-left">
              <div className="mt1">
                <label htmlFor="email">
                  Email {touched.email && errors.email && <span className="badge badge-danger">{errors.email}</span>}
                </label>
                <Field type="email" name="email" />
              </div>

              <div className="mt1">
                <label htmlFor="password">
                  Password{' '}
                  {touched.password && errors.password && <span className="badge badge-danger">{errors.password}</span>}
                </label>
                <Field type="password" name="password" />
              </div>
            </div>

            <div className="text-right">
              <a onClick={() => {
                ga('Onboarding', 'Click on Forgot password');
                setShowPasswordReset(true);
              }}>Forgot password</a>
            </div>
            {/* <CostBreakdown values={values} /> */}
            <div className="mt2">
              <button className="login_tracking button button-primary" type="submit" disabled={isSubmitting}>
                Log in
                {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft: '1em'}} />}
              </button>
            </div>
          <div className="mt2">
            Not registered yet? Sign up <a onClick={props.onGotoSignup}>here</a>.
          </div>
          </Form>
        )}
      </Formik>


      <ReactModal
        isOpen={showPasswordReset}
        className={`hirer-onboarding hirer-onboarding-${Config.themeName} hirer-onboarding-modal hirer-onboarding-modal-password-reset`}
        overlayClassName={`hirer-onboarding-backdrop hirer-onboarding-${Config.themeName} hirer-onboarding-backdrop-password-reset`}
        onRequestClose={() => setShowPasswordReset(false)}
      >
      <div className="hirer-form-container">
        <h2>Reset your password</h2>
        <p>
          You need to reset your password on our Hirer app. Once this is done, go back to this tab to sign in and complete your shift.
        </p>
        <div className="reset-actions">
        <span className="modal-back reset-back" onClick={() => setShowPasswordReset(false)}><FontAwesomeIcon icon={faChevronLeft}/> Go back</span>
          <p>
            <a
              href={Config.hirerAppUrl + '/login?passwordReset=1'}
              target="_blank"
              className="button button-primary"
              onClick={() => {
                ga('Onboarding', 'Confirm Forgot password');
                setShowPasswordReset(false)
              }}
            >
              Reset my password
            </a>
          </p>
        </div>  

        </div>
      </ReactModal>
    </div>
    </div>
  );
}
