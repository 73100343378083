import * as React from 'react';
import { MobileModalFieldWrapper } from '../MobileModalFieldWrapper';
import { useState, cloneElement, useRef } from 'react';
import { useFormikContext } from 'formik';
import { ModalFieldWrapper } from '../ModalFieldWrapper';

export function ShiftField(props: { children, 
    title:string,
    fieldNames:string[],
    isMobile:boolean,
    hiddenField?:string,
    customModalContent?,
    customMobileFieldContent?,
    modalRef?,
    scrollRef?,
    hasDesktopModal?:boolean,
    getModalOverride?,
    setModalOverride?,
    hasModalChild?:boolean}) {
        
    const [isModalOpenState, setIsModalOpenState] = useState<boolean>(false); 
    const [currentValues, setCurrentValues] = useState<any>('');
    const {  values } = useFormikContext();
    let formFieldRef = useRef(null);
    // console.log(props.scrollRef)

    let formElement;
    let hiddenField = props.hiddenField;
    let modalContent = props.children;
    let isConfirmDisabled:boolean = props.setModalOverride !== undefined;
    //overrides for modal opening and closing
    let setModalOpen = setIsModalOpenState;
    if(isConfirmDisabled){
        setModalOpen = props.setModalOverride;
    }
    let isOpen:boolean = isModalOpenState;
    if( props.getModalOverride !== undefined) {
        isOpen = props.getModalOverride();
    }
    let showModal = () =>{
        setModalOpen(true)
        setCurrentValues(values)
        setTimeout(function(){ 
            if(props.modalRef.current){
                props.modalRef.current.focus();
            }
        }, 10);
    }
    // this is required currently by category search

    if(props.isMobile){
        // clones the field for the form and put the rest into the modal 
        if(props.children.length > 1){
            formElement = props.children[0];
    
        }else{
            formElement = props.children;
        }
        if(props.customMobileFieldContent){
            formElement =  props.customMobileFieldContent;
        }
        formElement = cloneElement( formElement, {  onClick: () => {showModal()}, onFocus: () =>{}, innerRef:formFieldRef, readOnly:true } ); // input
        formElement = <div onClick={() =>{showModal()}}>{formElement}</div>
        if(props.customModalContent){
            if(!props.hasModalChild){
                modalContent = cloneElement(props.customModalContent,{children:props.children});
            }else{
                modalContent = props.customModalContent;
            }
        }
    } else {
        formElement = props.children;
        if(props.hasDesktopModal){
            formElement = cloneElement( formElement, { onClick: () => {showModal()}, onFocus: () =>{}, innerRef:formFieldRef } );
            if(props.customModalContent){
                modalContent = cloneElement(props.customModalContent,{children:props.children});
            }
        }
    }

    return(
        <>
            {props.isMobile &&(
                <>
                    {formElement}
                    <MobileModalFieldWrapper 
                    fieldNames={props.fieldNames}
                    title={props.title}
                    currentValues={currentValues}
                    isOpen={isOpen}
                    setIsOpen={setModalOpen}
                    hiddenField={hiddenField}
                    scrollRef={props.scrollRef}
                    formRef={formFieldRef}
                    isConfirmationDisabled={isConfirmDisabled}
                    >
                        {modalContent}
                    </MobileModalFieldWrapper>
                </>             
            )}
            {!props.isMobile &&(
                <>
                    {formElement}
                    <ModalFieldWrapper 
                    isOpen={isModalOpenState}
                    setIsOpen={setIsModalOpenState} >
                        {modalContent}
                    </ModalFieldWrapper>
                </>
            )}            
        </>
    );


}
