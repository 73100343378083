import * as React from 'react';
import { convertFromYYYYMMDDtoDDMMYYYY } from '../utils';

export function ShiftSummary(props: {startDate: string, startTime: string, endTime: string}) {
  //  format date
  const startdDate: string = convertFromYYYYMMDDtoDDMMYYYY(props.startDate);
    return (
        <div className="shift-row">
          <dl>
            <dt>Date:</dt>
            <dd>{startdDate}</dd>
          </dl>
          <dl>
            <dt>Start Time:</dt>
            <dd>{props.startTime}</dd>
          </dl>
          <dl>
            <dt>End Time:</dt>
            <dd>{props.endTime}</dd>
          </dl>
        </div>
    )

}