
import * as React from 'react';
import { ShiftTimeValue } from './ShiftFormValues';

import { RemoveShift } from '../Components/Shift/RemoveShift';
import { useViewport } from '../Hooks/viewport';


export function AdditionalShiftControls(props:{values, setFieldValue}) {
    let canAddMore: boolean = props.values.shifts.length < 10;
    let hasMultiShift: boolean = props.values.shifts.length > 1; 
    const { width } = useViewport();
    let isMobile: boolean = width <= 500;

    let remove = () =>{
        let shiftRow: ShiftTimeValue = new ShiftTimeValue(props.values.shifts.length);
        let shifts = props.values.shifts;
        shifts.pop(shiftRow);
        props.setFieldValue("shifts", shifts);
    }

if(props.values.type ==="stint"){
    return(<></>)
}
return(
    <div className="additional-shift-controls">
        {canAddMore && (
            <div className={`add-shift-item ${hasMultiShift ? '' : 'add-shift-multi'}`}
            onClick={() => add(props.values)}
            >
                <span className="blue-pill">+</span> Add a shift
            </div>
        )}
        {hasMultiShift && isMobile && (
        <>
            <RemoveShift isFirstRow={!hasMultiShift} remove={remove} />
        </>
        )}
    </div>
    )

    function add( values){
       let shiftRow: ShiftTimeValue = new ShiftTimeValue(values.shifts.length);
       let shifts = values.shifts;
       shifts.push(shiftRow);
       props.setFieldValue("shifts", shifts);
    }
}