import * as React from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { convertFromYYYYMMDDtoDDMMYYYY, getValueFromArray } from '../utils';

export function MobileModalFieldWrapper(props: { 
    children,
    title:string,
    currentValues,
    fieldNames:string[],
    isOpen:boolean,
    setIsOpen,
    hiddenField?:string,
    scrollRef?,
    formRef?,
    isConfirmationDisabled?:boolean
    }) {
    // hidden fields needs to iterated over
// add all the common modal elements
    const { setFieldValue, values } = useFormikContext();
    // props.formRef.current.focus();

    // need to be able to trigger this within the modal 
    let confirm =() => {
        props.setIsOpen(false);
        let value;
        if (props.fieldNames.length){
            props.fieldNames.map((fieldName, index)=>{   
                let fieldValue = getValue(fieldName,values);
                if(fieldName.toLocaleLowerCase().includes("date")){
                    fieldValue = convertFromYYYYMMDDtoDDMMYYYY(fieldValue);
                }
                if(fieldName.toLocaleLowerCase().includes("rate")){
                    setFieldValue("isVariableRate",values["isVariableRatePrelim"]);
                }
                value = `${index > 0 ? `${value}-` : ""}${fieldValue}` ;
            });
        }
        if(value && props.hiddenField){
            // date is sometimes saved as undefined
            //date formatting
            setFieldValue(props.hiddenField,value);
           
        }
        setTimeout(function(){ 
            props.formRef.current.blur();
            props.scrollRef.current.scrollIntoView({ block: 'center' });
        }, 10);

        //autofocus on the inpu
    }
    return(
        <ReactModal
        closeTimeoutMS={200}
        isOpen={props.isOpen}
          className={`hirer-onboarding shift-form-modal`}
          overlayClassName={`hirer-onboarding-backdrop`}
          onRequestClose={() => {}}>
           
           <div className="top-modal-bar">
                <span className="modal-shift-form-title">{props.title}</span>
                <div className="modal-action modal-close">
                    <FontAwesomeIcon icon={faTimes} onClick={() => {close()}} />
                </div>
                { !props.isConfirmationDisabled && (
                    <div className="modal-action modal-confirm">
                        <FontAwesomeIcon icon={faCheck} onClick={() => {confirm()}} />
                    </div>
                )}

            </div>
            <div className="modal-content">
                {/* <div><ErrorMessage name={props.fieldNames[0]} component="div" className="badge badge-danger"/> </div> */}
                {props.children}
            </div>
            { !props.isConfirmationDisabled && (
                <div className={"modal-confirm-button"}>
                    <button className="button button-primary modal-button" type="submit" onClick={() => {confirm()}} >  
                    DONE
                    </button>
                </div>
            )}

        </ReactModal>
    );

    function close(){
        props.setIsOpen(false);
        let combinedFieldVal = "";
        if (props.fieldNames.length){
            props.fieldNames.map((fieldName, index)=>{     
                let fieldVal = getValue(fieldName,props.currentValues)
                if(fieldVal !== undefined){
                    setFieldValue(fieldName,fieldVal);  
                }
                //check if its a date
                fieldVal = convertFromYYYYMMDDtoDDMMYYYY(fieldVal);
                combinedFieldVal = `${index > 0 ? `${fieldVal} - ` : ""}${getValue(fieldName,fieldVal)}` ;
            });
        } 

        
        setFieldValue(props.hiddenField,combinedFieldVal);
    }
    
    function getValue(fieldName,values){
        let value;
        // create utility function
        if(fieldName.includes(".")){
            value = getValueFromArray(fieldName, values); 
        }else{
            value = values[fieldName];  
        }
        return value;
    }
}
