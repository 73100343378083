import React from 'react';
import { Config } from "../../config";

interface AutocompleteProps {
  name: string;
  value: string;
  isMobile:boolean;
  innerRef: React.RefObject<any> ;
  onBlur(any): any,
  onSelect(place: google.maps.places.PlaceResult): void;
}

export class Autocomplete extends React.Component<AutocompleteProps> {

  async componentDidMount() {
    await this.waitForGoogleMaps();
    // console.log(this.ref)

    const bristol = new window.google.maps.Circle({
      center: {lat: 51.454514, lng: -2.58791},
      radius: 50000,
    });

    this.props.innerRef.current.addEventListener('blur', (e) => {
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
    });

    const autocomplete = new window.google.maps.places.Autocomplete(this.props.innerRef.current, {
      bounds: bristol.getBounds(),
      strictBounds: false,
      componentRestrictions: {
        country: ['gb','gl'],
      }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      this.props.onSelect(place);
    });
  }

  waitForGoogleMaps() {
    if (window.google && window.google.maps) {
      return;
    }

    return new Promise(resolve => {
      if (Config.googleApiKey && (!window.google || !window.google.maps)) {
        window['_callbackHirerOnboarding'] = resolve;

        const s = document.createElement('script');
        s.src = `https://maps.googleapis.com/maps/api/js?key=${Config.googleApiKey}&libraries=places&callback=_callbackHirerOnboarding`;
        document.head.append(s);

        return;
      }
    });
  }

  render() {
    return (
        <input type="text" ref={this.props.innerRef} name={this.props.name} className="dropdown-active" placeholder={this.props.value !== "" ? this.props.value : `Search a location...`}/>
    );
  }
}
