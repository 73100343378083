import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {App} from './src/App';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import {ga} from './src/utils';

/**
 * Renders the onboarding
 *
 * The function is available globally as `HirerOnboarding.render()`
 */
export function render(element: HTMLElement) {
  ga('Onboarding', 'View - Step 1');

  ReactModal.setAppElement(element);
  ReactDOM.render(React.createElement(App), element);
}
