import * as React from 'react';
import { useFormikContext } from 'formik';
import { fixNumber, createArrayFieldName, isShortNotice } from '../utils';
import { WarningModal } from '../DesktopModals/WarningModal';

export function TimeRangeModal(props:{index:number, children}) {
  const {  values } = useFormikContext();
  let currentVals :any  = values;
  let durationInHours = currentVals.getDurationInHours(props.index);
  if(!durationInHours){
    durationInHours = 0
  }
  durationInHours = fixNumber(durationInHours,1);
  let fieldName:string = createArrayFieldName("startDate",props.index);
  let hasShortNoticeWarning:boolean = isShortNotice(fieldName,values);
  // console.log(durationInHours)
  return (
    <div className="time-modal">
      {props.children}
      <div className="mt2 flex-center">
       Your shift will be around {durationInHours} hours.
      </div>
      {/* Insert contents of modal here */}
      {hasShortNoticeWarning && (
        <>
          <WarningModal className={"inline-warning-container"}/>
        </>
      )}
    </div>
  );
}