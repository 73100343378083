import axios, {AxiosResponse} from 'axios';
import {get} from 'lodash';

const API_URL = process.env.API_URL;

const instance = axios.create({
  baseURL: API_URL,
});

interface GraphqlResponseBody<T extends Object> {
  [key: number]: {
    data: T;
    errors: {
      message: string;
      path: string[];
      extensions: {[key: string]: string};
      locations: {line: number; column: number}[];
    }[];
  };
}

export interface SignupParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;

  onboarding: {
    venuePlace: string;
    venueLat: number;
    venueLng: number;
    venuePostalCode: string;
    venueVenue: string;
    category: string;
    shiftStint: boolean;
    averageWeeklyHours: number;
    date: string;
    endDate: string;
    startTime: string;
    endTime: string;
    totalStaffs: number;
    hourlyRate: number;
    screeningQuestions: String[];
  };
}

export interface SignupResponseData {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  referralCode: string;
  stripeToken: string;
  stripePaymentMethod: string;
}

export async function signup(data: SignupParams): Promise<SignupResponseData> {
  // url: POST /api/v2/hirers
  // data: class HirerSignup
  /*
      public String companyName;
      public String firstName;
      public String lastName;
      public String email;
      public String phone;
      public String password;
      public String referralCode;
      public String stripeToken;
      public String stripePaymentMethod;
   */
  const ret = await instance.post('/api/v2/hirers', data);

  handleError(ret);

  return ret.data;
}

export interface SigninParams {
  email: string;
  password: string;
}

export interface SigninResponseLoginData {
  id: string;
  token: string;
  hirerId: string;
  __typename: string;
}

export interface SigninResponseGraphqlData extends GraphqlResponseBody<{login?: SigninResponseLoginData}> {}

export async function signin(data: SigninParams): Promise<SigninResponseLoginData> {
  const ret: AxiosResponse<SigninResponseGraphqlData> = await instance.post(
    '/api/graphql',
    [
      {
        operationName: 'Login',
        variables: {username: data.email, password: data.password},
        query: `
mutation Login($username: String!, $password: String!, $oneSignalPlayerId: String) {
  login(input: {username: $username, password: $password, oneSignalPlayerId: $oneSignalPlayerId}) {
    id
    token
    hirerId
    __typename
  }
}
`,
      },
    ],
    {
      headers: {
        Authorization: 'limber-api-v2 undefined undefined',
      },
    }
  );

  handleError(ret);

  return ret.data[0].data.login;
}

interface AddVenueParams {
  clientId: string;
  lat: number;
  /**
   * Longitude...
   */
  lang: number;
  place: string;
  postalCode: string;
  venue: string;
  index: '';
}

interface AddVenueResponseData {
  index: number;
  isSuccess: boolean;
  message: string;
}

export async function addVenue(data: AddVenueParams, session: string, token: string): Promise<AddVenueResponseData> {
  const ret = await instance.post('/api/v1/client/addUpdateVenue', data, {
    headers: {
      Authorization: `limber-api-v2 ${session} ${token}`,
    },
  });

  handleError(ret);

  return ret.data;
}

interface AddJobDescriptionParams {
  category: string;
  /**
   * Optional description
   */
  categoryDescription: string;
  clientId: string;
  /**
   * Specific requirements
   */
  dressCode: string;
  index: string;
  pointOfContact: string;
}

interface AddJobDescriptionResponseData {
  index: number;
  isSuccess: boolean;
  message: string;
}

export async function addJobDescription(
  data: AddJobDescriptionParams,
  session: string,
  token: string
): Promise<AddJobDescriptionResponseData> {
  const ret = await instance.post('/api/v1/client/addUpdateDescription', data, {
    headers: {
      Authorization: `limber-api-v2 ${session} ${token}`,
    },
  });

  handleError(ret);

  return ret.data;
}

interface AddShiftParams {
  clientId: string;
  totalStaffs: number;
  // isAppliedWorkerToJob: string;
  index: string;
  stint: boolean;
  isNextDay: boolean,
  date: string; // formatted dd/mm/yyyy
  averageWeeklyHours: number;
  startTime: string; // hh:mm - optional if stint
  endTime: string; // hh:mm - optional if stint
  endDate: string; // formatted dd/mm/yyyy - required if stint

  payPerHourRates: {
    minimumAge: 18;
    rate: number;
  }[];
  screeningQuestions: String[]
}

interface AddShiftData {
  stint: boolean;
  job?: {
    id: string;
    // many other properties
  };
  jobs?: {
    id: string;
    // many other props
  }[];
  error: string;
  client: any;
  config: any;
}

export async function addShift(data: AddShiftParams, session: string, token: string): Promise<AddShiftData> {
  const ret = await instance.post(
    '/api/v1/job/addShift',
    {
      isAppliedWorkerToJob: false,
      appliedWorkers: 0,
      ...data,
    },
    {
      headers: {
        Authorization: `limber-api-v2 ${session} ${token}`,
      },
    }
  );

  handleError(ret);

  return ret.data;
}

interface PostShiftParams {
  shiftIds: string[];
  isGroupShift:boolean;
}

interface PostShiftData {
  id: string;
  __typename: string;
}

export interface PostShiftGraphqlData extends GraphqlResponseBody<{postShifts?: PostShiftData}> {}

//postShifts(shiftIds: [ID!]!, groupShifts: Boolean!): [Shift!]
export async function postShift(data: PostShiftParams, session: string, token: string): Promise<PostShiftData> {
  const ret: AxiosResponse<PostShiftGraphqlData> = await instance.post(
    '/api/graphql',
    [
      {
        operationName: 'PostShift',
        variables: {shiftIds: data.shiftIds, groupShifts: false},
        query: `
mutation PostShift($shiftIds: [ID!]!, $groupShifts: Boolean!) {
  postShifts(shiftIds: $shiftIds, groupShifts: $groupShifts) {
    id
    __typename
  }
}
`,
      },
    ],
    {
      headers: {
        Authorization: `limber-api-v2 ${session} ${token}`,
      },
    }
  );

  handleError(ret);

  return ret.data[0].data.postShifts[0];
}



interface SubmitPartialOnboardingParamsLimber {
  venueAddress: string;
  venuePlaceId: string;
  venueLat: number;
  venueLng: number;
  venuePostalCode: string;
  venueVenue: string;
  category: string;
  shiftStint: boolean;
  averageWeeklyHours: number;
  date: string;
  endDate: string;
  startTime: string;
  endTime: string;
  totalStaffs: number;
  hourlyRate: number;
}

interface SubmitOptionalsOnboardingParamsLimber {
  venueDisplayName:string;
  venueName:string;
  description: string;
  dressCode: String;
  pointOfContact: String;
  screeningQuestions:String[]
}



export interface SubmitPartialOnboardingGraphqlData extends GraphqlResponseBody<{onboardingShiftForm?: boolean}> {}

export async function submitPartialOnboardingLimber(data: SubmitPartialOnboardingParamsLimber): Promise<boolean> {
  const ret: AxiosResponse<SubmitPartialOnboardingGraphqlData> = await instance.post('/api/graphql', [
    {
      variables: {shift: data},
      query: `
mutation($shift: OnboardingShiftFormInput!) {
  onboardingShiftForm(shift: $shift)
}`,
    },
  ]);
  

  handleError(ret);

  return ret.data[0].data.onboardingShiftForm;
}

export interface EstimateActiveWorkersGraphqlData extends GraphqlResponseBody<{estimateActiveWorkers?: number}> {}

export async function estimateActiveWorkers(city: string, lat: number, lon: number) {
  const ret: AxiosResponse<EstimateActiveWorkersGraphqlData> = await instance.post('/api/graphql', [
    {
      variables: {
        city: city,
        lat: lat,
        lon: lon
      },
      query: `
        query($city: String, $lat: Float, $lon: Float) {
          estimateActiveWorkers(city: $city, lat: $lat, lon: $lon)
        }
      `
    }
  ]);
  handleError(ret);
  return ret.data[0].data.estimateActiveWorkers
}


export interface TopWorkersGraphqlData extends GraphqlResponseBody<{topWorkers?: [DisplayWorker]}> {}

export async function topWorkers(category: string, pageSize: number): Promise<[DisplayWorker]> {
  const ret: AxiosResponse<TopWorkersGraphqlData> = await instance.post('/api/graphql', [
    {
      variables: {
        category: category,
        pageSize: pageSize
      },
      query: `
query ($category: String, $pageSize: Int!){
  topWorkers (category: $category, pageSize: $pageSize) {
    avatarUrl
    firstName
    lastActive
    introduction
    reliabilityRank
    reliabilityRankDisplay
    shiftsCompletedCount
    shiftsCancelledCount
    shiftsAbsentCount
    ratings {
      venue {
        index
        name
      }
      category
      rating
    }
  }
}`,
    },
  ]);
  handleError(ret);
  return ret.data[0].data.topWorkers;
}

export interface DisplayWorker {
  avatarUrl: string
  firstName: string
  introduction: string
  reliabilityRank: number
  lastActive: string
  reliabilityRankDisplay: string
  shiftsCompletedCount: number
  shiftsCancelledCount: number
  shiftsAbsentCount: number
  ratings: [WorkerRating]
}

export interface WorkerRating {
  venue: Venue
  category: string
  rating: number
}

export interface Venue {
  index: number
  name: string
  location: Location
  address: string
  postalCode: string
}


export async function submitOptionalsOnboardingLimber(data: SubmitOptionalsOnboardingParamsLimber): Promise<boolean> {
  const ret: AxiosResponse<SubmitOptionalsOnboardingParamsLimber> = await instance.post('/api/graphql', [
    {
      variables: {optionals: data},
      query: `
mutation($optionals: OnboardingOptionalsFormInput!) {
  onboardingOptionalsForm(optionals: $optionals)
}`,
    },
  ]);
  

  handleError(ret);

  return ret.data[0].data.onboardingShiftForm;
}


function handleError(response: AxiosResponse) {
  if (response.data && response.data.isSuccess === false) {
    throw new ApiError(response.data.message || response.data.error, response);
  }

  // 4xx error on some endpoints
  if (`${response.status}`[0] === '4') {
    // expect res.data.reason?
    throw new ApiError(get(response, 'data.reason') || response.statusText, response);
  }

  // graphql errors
  if (response.data && get(response, 'data[0].errors[0].message')) {
    throw new ApiError(get(response, 'data[0].errors[0].message'), response);
  }
}

export class ApiError<T> extends Error {
  constructor(message: string, public response: AxiosResponse<T>) {
    super(message);
  }
}
