import {Category, CategoryGroup} from './categories';

export const categories: Category[] = [
  {
    id: 'Bar',
    displayName: 'Bar',
  },
  {
    id: 'Cocktails',
    displayName: 'Cocktails',
  },
  {
    id: 'Waiting',
    displayName: 'Waiting',
  },
  {
    id: 'Formal dining',
    displayName: 'Formal dining',
  },
  {
    id: 'Barista',
    displayName: 'Barista',
  },
  {
    id: 'Accountant',
    displayName: 'Accountant',
  },
  {
    id: 'Actor/actress',
    displayName: 'Actor/actress',
  },
  {
    id: 'Administrator',
    displayName: 'Administrator',
  },
  {
    id: 'Animator',
    displayName: 'Animator',
  },
  {
    id: 'Artist',
    displayName: 'Artist',
  },
  {
    id: 'Babysitting',
    displayName: 'Babysitting',
  },
  {
    id: 'Baker',
    displayName: 'Baker',
  },
  {
    id: 'Blogger',
    displayName: 'Blogger',
  },
  {
    id: 'Bookkeeper',
    displayName: 'Bookkeeper',
  },
  {
    id: 'Carpenter',
    displayName: 'Carpenter',
  },
  {
    id: 'Cleaning',
    displayName: 'Cleaning',
  },
  {
    id: 'Cloak room attendant',
    displayName: 'Cloak room attendant',
  },
  {
    id: 'Construction',
    displayName: 'Construction',
  },
  {
    id: 'Content creation',
    displayName: 'Content creation',
  },
  {
    id: 'Copywriter',
    displayName: 'Copywriter',
  },
  {
    id: 'Delivery',
    displayName: 'Delivery',
  },
  {
    id: 'DJ',
    displayName: 'DJ',
  },
  {
    id: 'Dog walker',
    displayName: 'Dog walker',
  },
  {
    id: 'Fruit picking',
    displayName: 'Fruit picking',
  },
  {
    id: 'Gardener',
    displayName: 'Gardener',
  },
  {
    id: 'Graphic Designer',
    displayName: 'Graphic Designer',
  },
  {
    id: 'Grocery delivery',
    displayName: 'Grocery delivery',
  },
  {
    id: 'Hair stylist',
    displayName: 'Hair stylist',
  },
  {
    id: 'Health care nurse',
    displayName: 'Health care nurse',
  },
  {
    id: 'Hotel porter',
    displayName: 'Hotel porter',
  },
  {
    id: 'Housekeeping',
    displayName: 'Housekeeping',
  },
  {
    id: 'Labourer',
    displayName: 'Labourer',
  },
  {
    id: 'Life coach',
    displayName: 'Life coach',
  },
  {
    id: 'Life guard',
    displayName: 'Life guard',
  },
  {
    id: 'Makeup artist',
    displayName: 'Makeup artist',
  },
  {
    id: 'Marketing',
    displayName: 'Marketing',
  },
  {
    id: 'Massage therapist',
    displayName: 'Massage therapist',
  },
  {
    id: 'Musician',
    displayName: 'Musician',
  },
  {
    id: 'Painter/decorator',
    displayName: 'Painter/decorator',
  },
  {
    id: 'Personal shopper',
    displayName: 'Personal shopper',
  },
  {
    id: 'Personal trainer',
    displayName: 'Personal trainer',
  },
  {
    id: 'Pet sitter',
    displayName: 'Pet sitter',
  },
  {
    id: 'Photographer',
    displayName: 'Photographer',
  },
  {
    id: 'Physiotherapist',
    displayName: 'Physiotherapist',
  },
  {
    id: 'Plumber',
    displayName: 'Plumber',
  },
  {
    id: 'Promoter',
    displayName: 'Promoter',
  },
  {
    id: 'Receptionist',
    displayName: 'Receptionist',
  },
  {
    id: 'Researcher',
    displayName: 'Researcher',
  },
  {
    id: 'Retail worker',
    displayName: 'Retail worker',
  },
  {
    id: 'Salesperson',
    displayName: 'Salesperson',
  },
  {
    id: 'Social media',
    displayName: 'Social media',
  },
  {
    id: 'Software engineer',
    displayName: 'Software engineer',
  },
  {
    id: 'Steward',
    displayName: 'Steward',
  },
  {
    id: 'Supply teaching',
    displayName: 'Supply teaching',
  },
  {
    id: 'Support worker',
    displayName: 'Support worker',
  },
  {
    id: 'Translator',
    displayName: 'Translator',
  },
  {
    id: 'Tutoring',
    displayName: 'Tutoring',
  },
  {
    id: 'UX designer',
    displayName: 'UX designer',
  },
  {
    id: 'Videographer',
    displayName: 'Videographer',
  },
  {
    id: 'Vlogger',
    displayName: 'Vlogger',
  },
  {
    id: 'Warehouse operative',
    displayName: 'Warehouse operative',
  },
  {
    id: 'Web developer',
    displayName: 'Web developer',
  },
  {
    id: 'Window cleaner',
    displayName: 'Window cleaner',
  },
  {
    id: 'Writer',
    displayName: 'Writer',
  },
  {
    id: 'Yoga instructor',
    displayName: 'Yoga instructor',
  },
  {
    id: 'Runner',
    displayName: 'Runner',
  },
  {
    id: 'Glass Collector',
    displayName: 'Glass Collector',
  },
  {
    id: 'Chef',
    displayName: 'Chef',
  },
  {
    id: 'Sous Chef',
    displayName: 'Sous Chef',
  },
  {
    id: 'Chef de Partie',
    displayName: 'Chef de Partie',
  },
  {
    id: 'Commis Chef',
    displayName: 'Commis Chef',
  },
  {
    id: 'Kitchen Porter',
    displayName: 'Kitchen Porter',
  },
  {
    id: 'Bar Back',
    displayName: 'Bar Back',
  },
  {
    id: 'Front of House',
    displayName: 'Front of House',
  }
];

export const categoryGroups: CategoryGroup[] = [
  {
    id: "New Categories",
    categories:[
      "Chef de Partie",
      "Sous Chef",
      "Commis Chef",
      "Front of House"
    ]
  },
  {
    id: "Hospitality staff",
    categories:[
        "Barista",
        "Bar",
        "Cocktails",
        "Formal dining",
        "Delivery",
        "Kitchen Porter",
        "Chef",
        "Chef de Partie",
        "Sous Chef",
        "Commis Chef",
        "Waiting",
        "Cloak room attendant",
        "Entertainment",
        "Cleaning",
        "Runner",
        "Front of House",
        "Glass Collector",
        "Bar Back"]
  },{
    id: "Hotel staff",
    categories:[
    "Housekeeping",
    "Receptionist",
    "Front of House",
    "Hotel porter",
    "Cleaning"]
  }];
