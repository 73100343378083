
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export function RemoveShift(props:{isFirstRow:boolean, remove, values?, setFieldValue?}) {

    return(
        <>
        {!props.isFirstRow && 
            <div className="remove-shift-item-mobile"
                    onClick={() => props.remove()}
                    >
                <FontAwesomeIcon className="limber-blue-icon" icon={faTrash}/>
                <span className="remove-shift-item-text">Remove shift</span>
            </div>
        }
        { props.isFirstRow &&
            <div className="shift-item-spacer-tablet">
            
            </div>
        }
        {!props.isFirstRow && 
            <div className="remove-shift-item-desktop"
                onClick={() => props.remove()}
                >
                <FontAwesomeIcon className="limber-blue-icon" icon={faTrash}/>
            </div>
        } 
        { props.isFirstRow &&
            <div className="shift-item-spacer-desktop">
            
            </div>
        }
        </>
    )



}