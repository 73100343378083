
import * as React from 'react';
import { Radio } from './Radio';

export function VariablePayRadioGroup(props:{values, setFieldValue, fixedTitle:string, variableTitle:string, isRadio:boolean, altFieldName?:string }) {

    let isVariableRate: boolean = props.altFieldName ? props.values[props.altFieldName]: props.values.isVariableRatePrelim;
    let fieldName:string =props.altFieldName ? props.altFieldName : 'isVariableRatePrelim';
    let radioField:string = props.isRadio ? "" : "tab-radio-field";
    let activeFixedTab  = "";
    let activeVariableTab = "";
    if(!props.isRadio){
        activeFixedTab = isVariableRate ? "" :"active-tab";
        activeVariableTab = isVariableRate ? "active-tab" :"";
    }
    if(props.isRadio){
        activeFixedTab = isVariableRate ? "" :"active-radio";
        activeVariableTab = isVariableRate ? "active-radio" :"";
    }



    // console.log(is)
    return(
        <div className="">
            <div className="mt radio-group">
            <label className={` ${radioField} ${activeFixedTab}`}>
                <Radio
                type="radio"
                name={fieldName}
                // value={false}
                checked={!isVariableRate}
                onChange={() => props.setFieldValue(fieldName, false)}
                />{' '}
                {props.fixedTitle}
            </label>
            <label className={`${radioField} ${activeVariableTab}`}> 
                <Radio
                type="radio"
                name={fieldName}
                // value={true}
                checked={isVariableRate}
                onChange={() => props.setFieldValue(fieldName, true)}
                />{' '}
                {props.variableTitle}
            </label>
            </div>
        </div>
        )
}