import React from 'react';
import { SignupForm, SignupFormValues } from "./SignupForm";
import {JobSummary} from './Summary/JobSummary';
import { ga } from "./utils";
import { ShiftFormValues } from './JobPosting/ShiftFormValues';
import {OptionalFormValues} from './Step4OptionalFields';
import {OptionalSummary} from './Summary/OptionalSummary';

export interface SignupProps {
  onGotoLogin();
  onSignupSubmit(values: SignupFormValues);
  shiftform: ShiftFormValues
  optionals: OptionalFormValues
  screeningQuestions: String[]
  backComponent?: any
}

export function Step6Signup(props: SignupProps) {
  return (
    <div className="hirer-onboarding-signup">
      <div className="hirer-form-container">
      <span className="mini-modal">
          {props.backComponent}
         </span> 
      <div className="header-section">
        <h2 className="mt2 mb0">New member?</h2>
        <div className="subheading">Sign-up to receive applicants</div>

        <div className="mt1">
          Already have an account?
          <br />
          Log in to post your work <a onClick={() => {
          ga('Onboarding', 'Click on Signin');

          props.onGotoLogin();
        }}>here</a>.
        </div>
      </div>
      <div className="mt2">
        <SignupForm onSubmit={props.onSignupSubmit}></SignupForm>
        <JobSummary values={props.shiftform} title="Your job summary"/>   
        <OptionalSummary optionals={props.optionals} screeningQuestions={props.screeningQuestions}/>
      </div>
    </div>
    </div>
  );
}
