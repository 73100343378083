import React from 'react';

export function Radio(props) {
  return (
    <>
      <span className="input-radio">
        <input {...props} />
        <span></span>
      </span>
    </>
  );
}
