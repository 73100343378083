import * as React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, useFormikContext } from "formik";
import { format } from "date-fns";
import { ShiftTimeValue } from '../../JobPosting/ShiftFormValues';
import { convertFromYYYYMMDDtoDDMMYYYY, getValueFromArray } from '../../utils';


export function DateField(props: {
  name:string,
  isAltFieldEnabled:boolean,
  safariTimeSelect:string[],
  placeHolder:string,
  className?:string,
  isInline?:boolean,
  updateMinimumWage,
  minimumWageSet
}) {

const { setFieldValue, values } = useFormikContext();
let selected = "";
if((props.isAltFieldEnabled || props.isInline) && props.safariTimeSelect.length > 1) {
  // handle multiple lengths
  
  let base: string = props.safariTimeSelect[0];
  let index: number = parseInt(props.safariTimeSelect[1]);
  let pickerSelector = props.safariTimeSelect[2];
  let shiftTimeValues: ShiftTimeValue[] = values[base];
  if(shiftTimeValues.length) {
    let shiftTime = shiftTimeValues[index];
    selected = shiftTime[pickerSelector]
  } else if(props.isAltFieldEnabled && props.safariTimeSelect.length === 1){
    selected =  values[props.safariTimeSelect[0]];
  }
}
const DatePickerInput = ({ value, onClick }) => {
  if(props.safariTimeSelect.length > 1){
    value = getValueFromArray(props.name,values);
  }else{
    value = values[props.name];
  }
  if(value){
    value = convertFromYYYYMMDDtoDDMMYYYY(value)
  }  
  return(
  
  <>
  <input
      name={`${props.name}Display`}
      type={"text"}
      value={value}
      placeholder="dd/mm/yyyy"
      onClick={onClick}
      autoComplete="chrome-off"
  />
  </>
)};
if(props.isInline && !selected){
  setDate(new Date());
}
return( 
<>
{(props.isAltFieldEnabled || props.isInline)  && (
  <>
  {!props.isInline &&(
    <DatePicker
    name={`${props.name}picker`}
    dateFormat="yyyy-MM-dd"
    selected={(selected && new Date(selected)) || null}
    onChange={(val) => { setDate(val)}}
    minDate={new Date()}
    customInput={<DatePickerInput value={values[props.name]} onClick={()=>{}}/>}
    placeholderText="yyyy-MM-dd"
    />
  )}
  {props.isInline &&(
    <DatePicker
    name={`${props.name}picker`}
    disabledKeyboardNavigation
    dateFormat="yyyy-MM-dd"
    selected={(selected && new Date(selected)) || null}
    onChange={(val) => { setDate(val)}}
    minDate={new Date()}
    customInput={<DatePickerInput value={values[props.name]} onClick={()=>{}}/>}
    placeholderText="yyyy-MM-dd"
    inline
    />
  )}

    <Field type="text" className="worker-hidden" name={props.name} onChange={()=>{}}/>
  </>
)}
  {(!props.isAltFieldEnabled && !props.isInline) && (
    <Field className={`${props.className}`} type="date" onChange={(val) => { setDateNormal(val)}} placeHolder={props.placeHolder} name={props.name} min={format(new Date(), 'yyyy-MM-dd')} />
  )}
  </>)

  function setDateNormal(event){
    let submitValue = event.target.value;
    setFieldValue(props.name,submitValue);
    let newMinWage = props.updateMinimumWage(new Date(submitValue), props.minimumWageSet)
    if(values["hourlyRate"] < newMinWage[2].rate){
      setFieldValue("hourlyRate", newMinWage[2].rate);
    }
  }

  function setDate(val){
    setFieldValue(`${props.name}picker`,val);
    let submitValue =format(val,"yyyy-MM-dd");
    setFieldValue(props.name,submitValue);
    let newMinWage = props.updateMinimumWage(new Date(submitValue), props.minimumWageSet)
    if(values["hourlyRate"] < newMinWage[2].rate){
      setFieldValue("hourlyRate", newMinWage[2].rate);
    }
  }

}
