import * as React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, useFormikContext } from "formik";
import { format } from 'date-fns';
import { ShiftTimeValue } from '../../JobPosting/ShiftFormValues';

export function TimeField(props: {name:string, safariTimeSelect:string[], isAltFieldEnabled:boolean, placeHolder:string, className?:string}) {

const { setFieldValue, values } = useFormikContext();

let selected ="";
let isAlt = true;
if(isAlt){
  let base: string = props.safariTimeSelect[0];
  let index: number = parseInt(props.safariTimeSelect[1]);
  let pickerSelector = props.safariTimeSelect[2];
  let shiftTimeValues: ShiftTimeValue[] = values[base];
  let shiftTime = shiftTimeValues[index];
  selected = shiftTime[pickerSelector]
}
const DatePickerInput = ({ value, onClick }) => (
  <input
      name={`${props.name}picker`}
      type={"text"}
      value={value}
      placeholder="HH:mm"
      onClick={onClick}
      autoComplete="off"
  />);


  return( 
  <>
  {isAlt && (
    <>
      <DatePicker 
      name={`${props.name}picker`}
      selected={(selected && new Date(selected)) || null}  onChange={(val) => {setTime(val)}}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      timeFormat="HH:mm"
      placeholderText="HH:mm"
      customInput={<DatePickerInput value={values[`${props.name}picker`]} onClick={()=>{}}/>}
      autoComplete={false}
      dateFormat="HH:mm"/>
      <Field type="hidden" name={props.name} onChange={()=>{}}/>
    </>

  )}
    {!isAlt && (
        <Field className={`${props.className}`}  type="time" name={props.name} />
    )}
  </>)

      function setTime(val){
        setFieldValue(`${props.name}picker`,val);
        let submitValue = format(val,"HH:mm");
        setFieldValue(props.name,submitValue);
      }

}
