import React from 'react';

export function ReliabilityRank(props:{rankTitle,rank}) {

return(
    <div className={`reliability reliability-${props.rank}`}>
        <div className="reliability-chart"></div>
        <div className="reliability-title">{props.rankTitle}</div>
    </div>
)

}