import {useState} from 'react';
import {} from 'date-fns';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {ShiftFormValues} from '../JobPosting/ShiftFormValues';
import {Config} from '../config';
import { fixNumber } from "../utils";

export function CostBreakdown(props: {values: ShiftFormValues, isOpen: boolean}) {
  let isPicker = Config.themeName.includes("picker");
  const [open, setOpen] = useState(isPicker || props.isOpen);

  // meed tp fix this for multi shifts
  let durationInHours = props.values.getTotalDurationHours();
  const wageCost = fixNumber(durationInHours * parseInt(props.values.workers) * props.values.hourlyRate);
  const holidayPayCost = fixNumber(wageCost * (Config.holidayPayRate));

  const nationalInsuranceRate = Config.getNationalInsuranceRate();
  const niCost = fixNumber((wageCost + holidayPayCost) * (nationalInsuranceRate));
  const limberFeeCost = fixNumber((wageCost + holidayPayCost + niCost) * (Config.feeRate));
  const totalExVatCost = fixNumber(wageCost + holidayPayCost + niCost + limberFeeCost);
  const vatCost = fixNumber(totalExVatCost * (Config.vatRate));
  const totalCost = fixNumber(totalExVatCost + vatCost);
  const valid = !Number.isNaN(totalCost);
  // add new line wages number of hours @ cost per hour
  if(Number.isNaN(durationInHours)){
    durationInHours = 0;
  }
  let wageBreakDown = fixNumber(durationInHours) +" hrs @ £"+ props.values.hourlyRate + " ph";
  if(props.values.type == "stint") {
    let numberOfWeeks = calculateNumberOfWeeks(props.values.startDate,props.values.endDate);
    let weeklyAverageHours = props.values.weeklyAverageHours
    if(weeklyAverageHours == undefined){
      weeklyAverageHours = 0;
    }
    wageBreakDown = fixNumber(numberOfWeeks) +" weeks at "+ weeklyAverageHours + " hrs pw";
  }

  return (
    <div className="cost-breakdown text-left">
      <div className="cost-breakdown-summary" onClick={() => valid && setOpen(!open)}>
        <span className="total-summary">Total cost</span>
        <span className="vat-summary">{valid ? currency(totalExVatCost) + ' plus VAT' : '-'}</span>

        {valid && (
          <div className="icon">
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </div>
        )}
      </div>

      {(open) && (
        <div className="cost-breakdown-contents mt2 text-left">
          <dl>
            <dt>Wage costs ({wageBreakDown})</dt>
            <dd> {currency(wageCost)}</dd>
          </dl>
          <dl>
            <dt>Holiday pay (@ {Config.holidayPayRate * 100}%)</dt>
            <dd>{currency(holidayPayCost)}</dd>
          </dl>
          <dl>
            <dt>NI and pension (@ {Math.round(nationalInsuranceRate * 100)}%)</dt>
            <dd>{currency(niCost)}</dd>
          </dl>
          <dl>
            <dt>Fee (@ {Config.feeRate * 100}%)</dt>
            <dd>{currency(limberFeeCost)}</dd>
          </dl>
          <div className="mt2"></div>
          <dl>
            <dt>VAT</dt>
            <dd>{currency(vatCost)}</dd>
          </dl>
          <dl className="total">
            <dt>Total inc. VAT</dt>
            <dd>{currency(totalCost)}</dd>
          </dl>

          <div className="learn-more">
            <a href={process.env.PRICING_PAGE_URL} target="_blank">
              Learn more about pricing
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

function calculateNumberOfWeeks(startDate: string, endDate: string):number{
  let start = new Date(startDate).getTime() ;
  let end = new Date(endDate).getTime(); 
  const diffTime = Math.abs(start - end);
  const diffDays = diffTime / (1000 * 60 * 60 * 24*7); 
  return diffDays;
}

export function currency(number: number): string {
  return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(number);
}
