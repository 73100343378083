import {Field, Formik, Form} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LinkedInTag from 'react-linkedin-insight';

export class SignupFormValues {
  firstname: string = '';
  lastname: string = '';
  email: string = '';
  password: string = '';

  static test() {
    return Object.assign(new SignupFormValues(), {
      firstname: 'Clement',
      lastname: 'Smith',
      email: `clement+${Date.now()}@limber.work`,
      password: 'asdfasdf',
    });
  }
}

export interface SignupFormProps {
  onSubmit(values: SignupFormValues): Promise<void>;
}

export function SignupForm(props: SignupFormProps) {
  return (
    <Formik
      initialValues={document.location.hash.includes('test') ? SignupFormValues.test() : new SignupFormValues()}
      validationSchema={Yup.object().shape({
        firstname: Yup.string().required('Required'),
        lastname: Yup.string().required('Required'),
        email: Yup.string().required('Required').email('Must be a valid email'),
        password: Yup.string().required('Required').min(8, 'Must be at least 8 characters'),
      })}
      onSubmit={async (values, {setSubmitting}) => {
        try {
          LinkedInTag.init("2735777", 'dc', false);
          LinkedInTag.track("6144937");
          await props.onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({isSubmitting, errors, touched}) => (
        <Form className="hirer-onboarding-signup-form">
          <div className="text-left">
            <div className="mt1">
              <label htmlFor="firstname">
                First name{' '}
                {touched.firstname && errors.firstname && (
                  <span className="badge badge-danger">{errors.firstname}</span>
                )}
              </label>
              <Field type="text" name="firstname" placeholder="Jo" />
            </div>

            <div className="mt1">
              <label htmlFor="lastname">
                Last name{' '}
                {touched.lastname && errors.lastname && <span className="badge badge-danger">{errors.lastname}</span>}
              </label>
              <Field type="text" name="lastname" placeholder="King" />
            </div>

            <div className="mt1">
              <label htmlFor="email">
                Email {touched.email && errors.email && <span className="badge badge-danger">{errors.email}</span>}
              </label>
              <Field type="email" name="email" placeholder="jo@thelocalpub.com" />
            </div>

            <div className="mt1">
              <label htmlFor="password">
                Password{' '}
                {touched.password && errors.password && <span className="badge badge-danger">{errors.password}</span>}
              </label>
              <Field type="password" name="password" placeholder="Make it a hard one" />
            </div>
          </div>

          <div className="mt1">
            {/* window.lintrk('track', { conversion_id: 6144937 }); */}
            <button className="signup_tracking button button-primary" type="submit" disabled={isSubmitting}>
              POST JOB
              {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft: '1em'}}/>}
            </button>
          </div>
        </Form>
      )}
    </Formik>
    
  );
}
