import React from 'react';
import {Config} from './config';

export function Step8Confirmation(props: {fromSignin: boolean}) {
  return props.fromSignin ? (
    <div className="hirer-onboarding-confirmation text-center">
     <div className="hirer-form-container">    
      <h2 className="mt0 mb0">We've posted your shift</h2>

      <p className="mt1">You can check its status on the limber hirer app.</p>
      <p>
        <a href={`${Config.hirerAppUrl}/shifts`} target="_blank" className="button button-primary">
          {Config.platformName} hirer
        </a>
      </p>
    </div>
    </div> 
  ) : (
    <div className="hirer-onboarding-confirmation text-center">
      <div className="hirer-form-container">    
      <h2 className="mt0 mb0">Check your inbox (and spam)</h2>
      <div className="subheading">We've sent you an email.</div>

      <p className="mt1">Please validate your email address by clicking the link in the email.</p>
    </div>
    </div>
  );

}
