import * as React from 'react';
import { useRef, useEffect } from 'react';


export function ModalFieldWrapper(props: { children, isOpen: boolean, setIsOpen, formRef?}) {
    // hidden fields needs to iterated over
// add all the common modal elements
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef)
    return(
        <>
            {props.isOpen && (
            <div className="overlay-container">
                <div className="overlay-container flex-center">
                    <div className="desktop-arrow"></div>
                </div>
                <div className="desktop-modal" ref={wrapperRef}>
                    {props.children}
                </div>
            </div>

            )}

        </>
    );

    function useOutsideAlerter(ref) {
        useEffect(() => {

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.setIsOpen(false)
                }
            }
    
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

}
