import React from 'react';
import {OptionalFormValues} from '../Step4OptionalFields';

export function OptionalSummary(props: {optionals: OptionalFormValues, screeningQuestions: String[]}) {

    return (
   <div className="optional-summary">
          {/* turn this into a component */}
      <div className="optional-summary-section">
        <div className="optional-summary-title">Description:</div>
        <span>{props.optionals.description}</span>
      </div>
      <div className="optional-summary-section">
        <div className="optional-summary-title">Point of Contact:</div>
        <span>{props.optionals.secondaryInformation}</span>
      </div>
      <div className="optional-summary-section">
        <div className="optional-summary-title">Screening questions:</div>
        <ol className="screening-questions-list">
        {props.screeningQuestions.map((value)=>
          <li>{value}</li>
        )}
        </ol>       
      </div>
    </div>
    )

}