import React  from 'react';
import ReactModal from 'react-modal';
import {Config} from '../config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes,faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {  ga } from "../utils";
import { ProgressBar } from './ProgressBar';
import { ShiftFormValues } from '../JobPosting/ShiftFormValues';

export function WizardModal(props: {children, shiftData: ShiftFormValues, step, backstep, gotoStep}) {
  // this should be a map
const {step, backstep, gotoStep} = props;
const STEP_3_CONFIRM = 3;
const STEP_4_OPTIONAL = 4;
const STEP_5_SCREENING_QUESTIONS = 5;
const STEP_6_SIGNUP = 6;
const STEP_7_SIGNIN = 7;
const STEP_8_CONFIRMATION = 8;
let backComponent = (<span className="modal-back" onClick={() => gotoStep(step-backstep)}><FontAwesomeIcon icon={faChevronLeft}/> Go back</span>);
let elements = React.Children.map(props.children, (child => { 
     if(child) {
       return React.cloneElement(child,  { backComponent: backComponent}) 
      }
  }
))

return (
    <ReactModal
    isOpen={true}
    className={`hirer-onboarding hirer-onboarding-${Config.themeName} hirer-onboarding-modal hirer-onboarding-modal-step-${step}`}
    overlayClassName={`hirer-onboarding-backdrop hirer-onboarding-${Config.themeName} hirer-onboarding-backdrop-step-${step}`}
    onRequestClose={() => {
      if ((step === STEP_6_SIGNUP || step === STEP_7_SIGNIN || step === STEP_3_CONFIRM || step ===STEP_4_OPTIONAL || step === STEP_5_SCREENING_QUESTIONS || step === STEP_8_CONFIRMATION)) {
        ga('Onboarding', 'Modal - close');
        gotoStep(1)
      }
    }}
  >
    {(step === STEP_6_SIGNUP || step === STEP_7_SIGNIN || step === STEP_3_CONFIRM || step === STEP_4_OPTIONAL || step === STEP_5_SCREENING_QUESTIONS  || step === STEP_8_CONFIRMATION) && (
      <>
        <div className={`progress-container `}>
          <ProgressBar step={step} />
        </div>
        <span className="modal-close">
          <FontAwesomeIcon icon={faTimes} onClick={() => gotoStep(1)} />
        </span>
      </>
      
    )}
    {elements}

  </ReactModal>
  );
}
