export function fixNumber(number: number, scale: number = 2): number {
  if(Number.isNaN(number)){
    number = 0;
  }
  return parseFloat(number.toFixed(scale));
}

export function ga(category: string, action: string, label: string = ''): void {
  if (window['gtag']) {
    console.log('gtag() event: ', category, action, label);
    window['gtag']('event', action, {
      'event_category' : category,
      'event_label' : label
    });
  } else if (window['ga']) {
    console.log('ga() event: ', category, action, label);
    window['ga']('send', 'event', category, action, label);
  }
}


export function convertFromYYYYMMDDtoDDMMYYYY(originalDate:string):string{
  if(originalDate){
    let datePart: string[] = originalDate.match(/\d+/g);
    // include some safety
    let year: string = datePart[0], month = datePart[1], day = datePart[2];
    return day+'/'+month+'/'+year;
  }
  return "";
}

export function getValueFromArray(fieldName,values){
  let fieldNameArray =  fieldName.split(".");
  let groupId:string = fieldNameArray[0];
  let fieldId:number = parseInt(fieldNameArray[1]);
  let fieldNameId :string =  fieldNameArray[2];
  return values[groupId][fieldId][fieldNameId];
}

export function getIdFromArray(fieldName): number {
  let fieldNameArray =  fieldName.split(".");
  let fieldId:number = parseInt(fieldNameArray[1]);
  return fieldId;
}

export function createArrayFieldName(field:string,index:number): string{
  return `shifts.${index}.${field}`
}

export function isShortNotice(fieldName,values){
  let hasShortNoticeWarning:boolean = false;
  if(fieldName.toLocaleLowerCase().includes("date")){
    let today: Date = new Date();   
    let date: Date = new Date(getValueFromArray(fieldName,values));
    // extract id from fieldname
    let isOnSameDay:boolean  = isSameDay(date, today);
    // need to get time values to get if it is within last 6 hours
    let shiftRowItems = values["shifts"];
    let startTime = "";
    if(shiftRowItems.length > 0){
        let shiftRowItem = shiftRowItems[getIdFromArray(fieldName)];
        if(shiftRowItem){
            startTime = shiftRowItem["startTime"];
            if(startTime && isOnSameDay){
                let startHour = parseInt(startTime.split(":")[0]);
                let timeDiff = startHour - today.getHours()
                hasShortNoticeWarning = (timeDiff <= 6) && (timeDiff > 0)
            }
        }   
    }
  }
  return hasShortNoticeWarning;
}


export function isSameDay(a, b) {
  return a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate()=== b.getDate()
}