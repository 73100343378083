import {JobSummary} from './Summary/JobSummary';
import React from 'react';
import {Field, Formik, Form} from 'formik';
import * as Yup from 'yup';
import {Config} from './config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LinkedInTag from 'react-linkedin-insight';

export class OptionalFormValues {
    description: string = '';
    secondaryInformation: string = '';
    venueDisplayName: string = '';
}
export function Step4OptionalFields(props: {oldValues:OptionalFormValues, onSubmit(values:OptionalFormValues): Promise<void>, onSkip,  backComponent?}) {
    let secondaryInfoLabel = "Point of contact";
    
    if(Config.themeName.includes("picker")){
        secondaryInfoLabel = "Accomodation description"
    }

    return(
    <div className="hirer-onboarding-signup">    
      <div className="hirer-form-container">
        <span className="mini-modal">
          {props.backComponent}
         </span> 
         <div className="header-section">
          <h3 className="modal-section-title">
            <strong>
              Add final details
            </strong>
          </h3>
          <p className="subheading">
              The more detail, the better prepared your workers.
          </p>
        </div>
          <Formik
          initialValues={props.oldValues}
          validationSchema={Yup.object().shape({
            })}
        onSubmit={async (values, {setSubmitting}) => {
          try {
            LinkedInTag.init("2735777", 'dc', false);
            LinkedInTag.track("6144921");
            await props.onSubmit(values);
          } catch {
            setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, errors, touched}) => (
          <Form className="hirer-onboarding-signup-form">
            <div className="text-left">
            <div className="mt1">
                <label htmlFor="venueDisplayName">
                  Venue Display Name{' '} {touched.venueDisplayName && errors.venueDisplayName && <span className="badge badge-danger">{errors.venueDisplayName}</span>}
                </label>
                <Field type="text" name="venueDisplayName" placeholder="How the venue name will look to applicants" />
              </div>

              <div className="mt1">
                <label htmlFor="description">
                  Description{' '}
                  {touched.description && errors.description && <span className="badge badge-danger">{errors.description}</span>}
                </label>
                <Field className="descriptionInput" component="textarea" rows="3"  name="description" placeholder="Talk about your business or the type of skills you need along with any dress code requirements you have. The more detailed you can be, the better prepared your applicants."/> 
              </div>
              {/* swap based on env */}
              <div className="mt1">
                <label htmlFor="secondaryInformation">
                {secondaryInfoLabel} {touched.secondaryInformation && errors.secondaryInformation && <span className="badge badge-danger">{errors.secondaryInformation}</span>}
                </label>
                <Field type="text" name="secondaryInformation" placeholder="Who will greet them on arrival" />
              </div>

            </div>

            <div className="mt1 text-center">
              {/* window.lintrk('track', { conversion_id: 6144921 }); */}
              <button className="add_desc_tracking button button-primary" type="submit" disabled={isSubmitting}>
              ADD DETAILS  

                {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft: '1em'}}/>}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt1 text-center">    
          <a className="button-tertiary" type="submit"  onClick={props.onSkip}>
              SKIP            
          </a>
      </div> 
      </div>
    </div>
    )
}