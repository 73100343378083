import * as React from 'react';
import { Category, CategoryGroup } from '../data/categories';
import { categoryGroups } from '../data/data-limber';

export function CategorySearchDropdown(props:{isEnabled:boolean, matchedCategories: Category[], onSetIsDropdownEnabled, onSelectItem, isMobile:boolean} ) {
    let catgroups: CategoryGroup[] = categoryGroups.map((cat)=>{
        return {...cat};
    });

    // do some matching between matched categories and the categories in the groups
    let filteredCats: CategoryGroup[]  = catgroups.filter((group)=>{
        group.categories = group.categories.filter((cat)=>{
            return isInMatchList(cat) 
        })
        return group.categories.length > 0;
    })
    return(
        <div className={` ${(props.isEnabled || props.isMobile) ? "worker-dropdown" : "worker-dropdown worker-hidden"}`}>
            <div className="worker-seperator"></div>
            {filteredCats.map((catGroup,index)=>(
                <div  key={index} className="categorySection">
                    <div className="catGroup-title">{catGroup.id}</div>
                    <div>
                        {catGroup.categories.map((cat)=>(
                            <label key={cat} className={`worker-option-container`}>
                                <input type="button" className="category-option" onClick={() => props.onSelectItem(cat)}/>
                                <span className="category-text">{cat}</span> 
                            </label>
                        ))}
                    </div>
                </div>
            ))}
            
        </div>
    )

    function isInMatchList(category: string){
        return props.matchedCategories.filter((cat)=>{
            return cat.displayName === category
        }).length > 0;
    }

}