
import * as React from 'react';
import {  ErrorMessage } from 'formik';
import { useViewport } from '../../Hooks/viewport';

export function ShiftCell(props:{children, fieldName: string, label?: string, canTriggerError: boolean, isAltFieldEnabled?:boolean, errors?, className?: string}) {
  // move to utils
  const { width } = useViewport();
  let isMobile: boolean = width <= 500;
return(
    <div className={`input-group-cell ${props.className} ${
        props.canTriggerError ? 'validation-error' : ''
      }`}>
        {!isMobile &&(
          <>
              <div className="flex-error  flex-error-location">
                <ErrorMessage name={props.fieldName} component="div" className="badge badge-danger"/> 
              </div>
              
                <label className="field-label" htmlFor={props.fieldName}>{props.label && (props.label)}  </label>
              
          </>
        )}    
        {isMobile && (
          <>
            <ErrorMessage name={props.fieldName} component="div"  className="badge mobile-shift-cell-badge badge-danger"/>                     
          </>
        )} 
      {props.children}
</div>
    )

}