import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';

import 'react-google-places-autocomplete/dist/index.min.css';
import './style/app.scss';
import {Step1ShiftForm} from './JobPosting/Step1ShiftForm';
import {ShiftFormValues} from './JobPosting/ShiftFormValues';
import {Step2Searching} from './Step2Searching';
import {Step5ScreeningQuestions} from './Step5ScreeningQuestions';
import {Step6Signup} from './Step6Signup';
import {Step7Signin} from './Step7Signin';
import {Step8Confirmation} from './Step8Confirmation';
import {Config} from './config';
import { 
  addJobDescription,
  addShift,
  addVenue,
  postShift,
  signup,
  submitPartialOnboardingLimber,
  submitOptionalsOnboardingLimber,
  DisplayWorker,
  topWorkers,
  estimateActiveWorkers
} from "./ApiClient";
import format from 'date-fns/format';
import { fixNumber, ga } from "./utils";
import {SignupFormValues} from './SignupForm';
import { Step3Confirm } from './Step3Confirm';
import {WizardModal} from './Components/WizardModal'
import {Step4OptionalFields, OptionalFormValues} from './Step4OptionalFields'
import {random} from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

// this should be a map

const STEP_1_SHIFT_FORM = 1;
const STEP_2_SEARCHING = 2;
const STEP_3_CONFIRM = 3;
const STEP_4_OPTIONAL = 4;
const STEP_5_SCREENING_QUESTIONS = 5;
const STEP_6_SIGNUP = 6;
const STEP_7_SIGNIN = 7;
const STEP_8_CONFIRMATION = 8;

const STEP_2_TIMEOUT = 5000;

export function App() {


  function createPayPerHourRates(){
    let payPerHourRates = []
    if(shiftData.isVariableRate) {
      payPerHourRates = minimumWageState.map(function(wage){
        let rate = shiftData.aboveMinWageHourlyRate + wage.rate;
        return { minimumAge:wage.minimumAge, rate: fixNumber(rate * 100, 0) }
      });
    } else {
      let pay = {
        minimumAge: 18,
        rate: fixNumber(shiftData.hourlyRate * 100, 0),
      }
      payPerHourRates.push(pay);
    }
    return payPerHourRates;
  }

  //use context/reducer for global states
  const [shiftData, setShiftData] = useState<ShiftFormValues>(
     new ShiftFormValues()
  );
  const [optionalData, setOptionalData] = useState<OptionalFormValues>(
     new OptionalFormValues()
  );

  const [topWorkerList, setTopWorkerList] = useState<DisplayWorker[]>(
    []
  );

  const [screeningQuestionsData, setScreeningQuestionsData] = useState<String[]>(
     []
  );

  const [countData, setCountData] = useState<number>(
    random(10, 20)
  )

  const [fromSignin, setFromSignin] = useState(false);
  const [apiError, setApiError] = useState('');

  /**
   * Steps:
   *
   *   1. Shift form
   *   2. Searching
   *   3. Confirm/Summary
   *   3. Signup+ optional additional details
   *   4. Login + optional additional details
   *   5. Confirmation
   */
  const [step, setStep] = useState(
    document.location.hash.match(/step\d/) ? parseInt(document.location.hash.match(/step(\d)/)[1]) : 1
  );

  function gotoStep(step: number, isBackStep?:boolean) {
    // ga('Onboarding', `View - Step ${n}`);
    if(!isBackStep){
      switch(step){
        case 2:
          console.log("Onboarding Funnel: Basic requirements - Fill in the shift posting form and hit next ");
          ga("Onboarding Funnel","Basic requirements - Fill in the shift posting form and hit next");
          break;
        case 3:
          break;
        case 4:
          console.log("Onboarding Funnel: Confirm summary 1 - Confirm summary after number of workers modal ");
          ga("Onboarding Funnel","Confirm summary 1 - Confirm summary after number of workers modal");
          break;
        case 5:
          console.log("Onboarding Funnel: Complete additional info - Skip or next the description ");
          ga("Onboarding Funnel","Complete additional info - Skip or next the description");
          break;
        case 6:
          console.log("Onboarding Funnel: Complete screening questions - Skip or next the screening question");
          ga("Onboarding Funnel","Complete screening questions - Skip or next the screening question");
          break;
      }
    }

    setStep(step);
  }

  //  create partial onboarding for skip as well

  async function onShiftFormSubmit(values: ShiftFormValues) {
    setShiftData(values);
    // ga('Onboarding', 'Submit shift form');
    // partial onboarding moved earlier
    onPartialOnboardingSubmit(values);
    gotoStep(STEP_2_SEARCHING);
  }


  async function onOptionalFormSubmit(values: OptionalFormValues) {
    setOptionalData(values);
    // ga('Onboarding', 'Submit optional form');
    gotoStep(STEP_5_SCREENING_QUESTIONS);

    //  do partial onboarding after optional data
  }

  async function onScreeningQuestionsFormSubmit(values: String[]) {
    // ga('Onboarding', 'Submit screening questions form');
    setScreeningQuestionsData(values);
    
    onOptionalOnboardingSubmit(optionalData,values)
    gotoStep(STEP_6_SIGNUP);
  }

  async function onOptionalOnboardingSubmit(optionals: OptionalFormValues, screeningQuestions: String[]){

    let formItems ={
      venueDisplayName: optionals.venueDisplayName,
      venueName: shiftData.googlePlace.name,
      description:optionals.description,
      dressCode: "",
      pointOfContact: optionals.secondaryInformation,
      screeningQuestions:screeningQuestions
    }
    await submitOptionalsOnboardingLimber(formItems);
  }

  async function onPartialOnboardingSubmit(values: ShiftFormValues){
    let formItems = {
      venueAddress: values.googlePlace.formatted_address,
      venuePlaceId: values.googlePlace.place_id,
      venueLat: values.googlePlace.geometry.location.lat(),
      venueLng: values.googlePlace.geometry.location.lng(),
      venuePostalCode: values.googlePlace.address_components.find((c) => c.types.includes('postal_code'))
        .long_name,
      venueVenue: values.googlePlace.name,
      category: values.category,
      shiftStint: values.type === 'stint',
      averageWeeklyHours: values.type === 'stint' ? values.weeklyAverageHours : undefined,
      date: format(values.getStartDate(), 'dd/MM/yyyy'),
      endDate: values.type === 'stint' ? format(values.getEndDate(), 'dd/MM/yyyy') : undefined,
      startTime: values.shifts[0].startTime,
      endTime: values.shifts[0].endTime,
      totalStaffs: parseInt(values.workers),
      hourlyRate: fixNumber(values.hourlyRate * 100, 0),  
    }
    await submitPartialOnboardingLimber(formItems);
  }

  async function onSignupSubmit(values: SignupFormValues) {
    try {
      console.log("Onboarding Funnel: Confirm summary 2 - Confirm the final summary");
      ga("Onboarding Funnel","Confirm summary 2 - Confirm the final summary");
      signUp(values);
      gotoStep(STEP_8_CONFIRMATION);
    } catch (err) {
      setApiError(err.message);

      ga('Onboarding', 'Signup error', err.message);
    }
  }

  async function signUp(values: SignupFormValues){
    // handle group shifts
    let onboarding =  {
      venuePlace: shiftData.googlePlace.formatted_address,
      venueLat: shiftData.googlePlace.geometry.location.lat(),
      venueLng: shiftData.googlePlace.geometry.location.lng(),
      venuePostalCode: shiftData.googlePlace.address_components.find((c) => c.types.includes('postal_code'))
        .long_name,
      venueVenue: shiftData.googlePlace.name,
      category: shiftData.category,
      shiftStint: shiftData.type === 'stint',
      averageWeeklyHours: shiftData.type === 'stint' ? shiftData.weeklyAverageHours : undefined,
      date: format(shiftData.getStartDate(), 'dd/MM/yyyy'),
      endDate: shiftData.type === 'stint' ? format(shiftData.getEndDate(), 'dd/MM/yyyy') : undefined,
      startTime: shiftData.shifts[0].startTime,
      endTime: shiftData.shifts[0].endTime,
      totalStaffs: parseInt(shiftData.workers),
      hourlyRate: fixNumber(shiftData.hourlyRate * 100, 0),
      screeningQuestions:screeningQuestionsData
    }
    let optionals = {}

    optionals = {
      venueDisplayName:optionalData.venueDisplayName,
      description:optionalData.description,
      pointOfContact:optionalData.secondaryInformation
    }
    onboarding = {...onboarding,...optionals }
    let signupPostParams = {
      firstName: values.firstname,
      lastName: values.lastname,
      email: values.email,
      password: values.password,
      onboarding: onboarding
    }

    // configure onboarding
    const signupResponse = await signup(signupPostParams);
  }

  async function onSignin(session: {id: string; token: string; hirerId: string}): Promise<void> {
    try {
      console.log("Onboarding Funnel: Confirm summary 2 - Confirm the final summary");
      ga("Onboarding Funnel","Confirm summary 2 - Confirm the final summary");      
      // continue job creation + shift posting
      signIn(session);

      setFromSignin(true);
      gotoStep(STEP_8_CONFIRMATION);
    } catch (err) {
      setApiError(err.message);

      ga('Onboarding', 'Signin error', err.message);
    }
  }

  async function signIn(session: {id: string; token: string; hirerId: string}){
    const venue = await addVenue(
      {
        clientId: session.hirerId,
        place: shiftData.googlePlace.formatted_address,
        lat: shiftData.googlePlace.geometry.location.lat(),
        lang: shiftData.googlePlace.geometry.location.lng(),
        postalCode: shiftData.googlePlace.address_components.find((c) => c.types.includes('postal_code')).long_name,
        venue: shiftData.googlePlace.name,
        index: '',
      },
      session.id,
      session.token
    );

    const jobDescription = await addJobDescription(
      {
        clientId: session.hirerId,
        category: shiftData.category,
        index: `${venue.index}`,
        categoryDescription: optionalData.description,
        dressCode:"" ,
        pointOfContact: optionalData.secondaryInformation,
      },
      session.id,
      session.token
    );

    let shiftResponses = [];
    // save teh minimum wage rates into config add type
    let payPerHourRates = createPayPerHourRates();

    shiftResponses = shiftData.shifts.map(async function(shiftItem,index){
    let isNextDay: boolean = shiftData.isMoreThanOneDay(index);
      //pay per hour rates
    const addShiftResponse = await addShift(
        {
          clientId: session.hirerId,
          stint: shiftData.type === 'stint',
          averageWeeklyHours: shiftData.type === 'stint' ? shiftData.weeklyAverageHours : undefined,
          date: format(shiftData.getStartDate(index), 'dd/MM/yyyy'),
          endDate: shiftData.type === 'stint' ? format(shiftData.getEndDate(index), 'dd/MM/yyyy') : undefined,
          isNextDay: isNextDay,
          index: `${jobDescription.index}`,
          payPerHourRates: payPerHourRates,
          startTime: shiftItem.startTime,
          endTime: shiftItem.endTime,
          totalStaffs: parseInt(shiftData.workers),
          screeningQuestions: screeningQuestionsData,
        },
        session.id,
        session.token
      );      
      return addShiftResponse;
    });
    const responses =  await Promise.all(shiftResponses);
    if(responses.length > 1) {
      const postedShift = await postShift(
        {
          shiftIds: (responses.map((shiftResponse) => shiftResponse.job.id) ),
          isGroupShift: shiftData.isGroupShift
        },
        session.id,
        session.token
      );
    } else {
      const postedShift = await postShift(
        {
          shiftIds: (responses[0].jobs ? responses[0].jobs.map((s) => s.id) : [responses[0].job.id]),
          isGroupShift: shiftData.isGroupShift
        },
        session.id,
        session.token
      );
    }

  }
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);

    if (step === STEP_2_SEARCHING) {
      if(shiftData) {
        topWorkers(shiftData.category,10).then((data) => {
          if(data.length > 0){
            setTopWorkerList(data);
            gotoStep(STEP_3_CONFIRM);
            estimateActiveWorkers(
              shiftData.googlePlace.vicinity,
              shiftData.googlePlace.geometry.location.lat(),
              shiftData.googlePlace.geometry.location.lng()
              ).then((activeWorkers) => {
                if(activeWorkers > data.length) {
                  setCountData(activeWorkers);
                } else {
                  setCountData(data.length);
                }

              });
          }
        });

      }
    }
  });

  let backstep = 1;
  if (step === STEP_3_CONFIRM ) {
    backstep= 2;      
  } else {
    backstep =1;
  }
  let minimumWage = Config.minimumWage; //TODO: replace with a query

  minimumWage = minimumWage.slice(minimumWage.length-6,minimumWage.length);
  let previousYearsMinimumWage = minimumWage.slice(0, minimumWage.length-3);
  let currentYearsMinimumWage = minimumWage.slice(minimumWage.length-3,minimumWage.length);
  let minimumwageSet = {
    previousYearsMinimumWage:previousYearsMinimumWage,
    currentYearsMinimumWage:currentYearsMinimumWage
  }
  const [minimumWageState, setMinimumWageState] = useState(currentYearsMinimumWage);

  function updateMinimumWage(date,minimumwageSet){ 
    if(minimumwageSet){
      if(!date || date === "Invalid Date"){
        date= new Date();
      }
      let threshold: Date = new Date(minimumwageSet.currentYearsMinimumWage[0].period.startAt);
      let minimumWage;
      if(date.getTime() >= threshold.getTime()){
        minimumWage = minimumwageSet.currentYearsMinimumWage;
      } else {
        minimumWage = minimumwageSet.previousYearsMinimumWage;
      }
      setMinimumWageState(minimumWage);
      return minimumWage;
    }

  }

  useEffect(()=>{
    updateMinimumWage(new Date(),minimumwageSet);
  },[]);
  return (
    <div className={`hirer-onboarding hirer-onboarding-${Config.themeName}`}>
    <ReactPlaceholder type='media' rows={7} ready={isReady}>
        <Step1ShiftForm
        onSubmit={onShiftFormSubmit}
        updateMinimumWage={updateMinimumWage}
        minimumWageSet={minimumwageSet}
        minimumWage={minimumWageState}
        /> 

        {step > STEP_1_SHIFT_FORM && (
          <WizardModal 
          shiftData={shiftData}
          step={step}
          backstep={backstep}
          gotoStep={gotoStep}>
            {step === STEP_2_SEARCHING && <Step2Searching />}
            {step === STEP_3_CONFIRM && 
              <Step3Confirm 
              count={countData}
              topWorkerList={topWorkerList}
              values={shiftData}  
              setTopWorkerList={setTopWorkerList}
              onGotoLogin={() => gotoStep(STEP_4_OPTIONAL)} />
            }
            {step === STEP_4_OPTIONAL && 
              <Step4OptionalFields
              oldValues={optionalData}
              onSubmit={onOptionalFormSubmit} 
              onSkip={() => gotoStep(STEP_5_SCREENING_QUESTIONS)} />
            }
            {step === STEP_5_SCREENING_QUESTIONS && 
              <Step5ScreeningQuestions 
              oldValues={screeningQuestionsData}
              onSubmit={onScreeningQuestionsFormSubmit} 
              onSkip={() => gotoStep(STEP_6_SIGNUP)} />
            }
            {step === STEP_6_SIGNUP && (
              <Step6Signup 
              shiftform={shiftData} 
              onSignupSubmit={onSignupSubmit} 
              onGotoLogin={() => gotoStep(STEP_7_SIGNIN)} 
              optionals={optionalData}
              screeningQuestions={screeningQuestionsData}
              />
            )}
            {step === STEP_7_SIGNIN && 
              <Step7Signin 
              onSignin={onSignin} 
              onGotoSignup={() => gotoStep(STEP_6_SIGNUP)} 
              />}
            {step === STEP_8_CONFIRMATION && 
              <Step8Confirmation 
              fromSignin={fromSignin} />
            }
          </WizardModal>
        )}

        {apiError && (
          <ReactModal
            isOpen={true}
            className={`hirer-onboarding hirer-onboarding-${Config.themeName} hirer-onboarding-modal hirer-onboarding-modal-error`}
            overlayClassName={`hirer-onboarding-backdrop hirer-onboarding-${Config.themeName} hirer-onboarding-backdrop-error`}
          >
            <h2>Something went wrong...</h2>
            <p>
              There was an error while communicating with the {Config.platformName} app: <strong>{apiError}</strong>.
            </p>
            <p>
              In the meantime, we recommend you log in on our{' '}
              <a href={Config.hirerAppUrl} target="_blank">
                Hirer app
              </a>{' '}
              to verify the information already sent.
            </p>
          </ReactModal>
        )}
      </ReactPlaceholder>
    </div>
  );
}
