import * as Yup from 'yup';
import { Config } from '../config';
 // need to write tests to put the date into the future beyond now
const getShiftFormValidationSchema = (minimumWage) => {
   return Yup.object().shape({
    // categoryGroup: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    preLoadedPlace: Yup.string().nullable(),
    workers: Yup.number().required('Required').min(1),
    hourlyRate: Yup.number().when("isVariableRate",{
      is:false,
      then: Yup.number()
      .required('Required')
      .min(minimumWage[2].rate, 'Must be at least ' + minimumWage[2].rate)
    }),
    aboveMinWageHourlyRate:  Yup.number().when("isVariableRate",{
      is:true,
      then: Yup.number()
      .required('Required')
    }),
      // this needs to support the variable wage
    isVariableRate: Yup.boolean(),
      // only on stint
    startDate: Yup.string().when('type', {
      is: 'stint',
      then:Yup.string()
      .required('Required')
      .test('dateAfter','Must be today or later', dateValidation)
    }),
    endDate: Yup.mixed().when('type', {
      is: 'stint',
      then: Yup.string()
        .required('Required')
        .test('after', 'Must be after start time', function (value) {
          if(value === ""){
            return false
          }
          return value > this.parent.startDate;
        }),
    }),
    weeklyAverageHours: Yup.mixed().when('type', {
      is: 'stint',
      then: Yup.number().required('Required').min(1),
    }),
    shifts: Yup.array()
    .when('type',{
      is:'shift',
      then: Yup.array().of(Yup.object().shape({
          startDate: Yup.string()
          .required('Required')
          .test('dateAfter','Must be today or later', dateValidation),
          endTime: Yup.string()
          .required('Required'),
          startTime: Yup.string()
          .required('Required')
          .test('timeAfter','Must be in the future',function(value){
            return timeValidation(value, this.parent.startDate)
          })          
      }))
    }),         
    isGroupShift: Yup.boolean(),
    isLoadedLocation: Yup.boolean(),
    //this should only validate if its not loaded
    googlePlace: Yup.object()
    .when('isLoadedLocation',{
      is: false,
      then: Yup.object()
      .nullable()
      .required('Required')
      .test('isAVenue', 'Must be a venue', function (value) {
          if (!value) {
            return false;
          }
          const postalCodePart = value.address_components.find((c) => c.types.includes('postal_code'));
          return !!(postalCodePart && postalCodePart.long_name !== '');
        }),
    }).when('isLoadedLocation',{
      is: true,
      then: Yup.object().nullable()
    })

  })
}

const dateValidation = (value) =>{
  if(value === "" || value === undefined){
    return false
  }
  let todaysDate:Date = new Date();
  let shiftStart:string = new Date(value).toISOString();
  todaysDate.setUTCHours(0,0,0,0);
  let today:string = todaysDate.toISOString();
  // console.log(shiftStart >= today)
  return shiftStart >= today
}

const timeValidation = (value,startDate) =>{
  if(value === "" || value === undefined){
    return false
  }
  let todaysDate:Date = new Date();
  // if no start date is set set it to today
  if(startDate ==""){
    startDate = new Date().toISOString();
  }
  let shiftStart:string = new Date(startDate).toISOString();
  let nowHours = todaysDate.getHours();
  let nowMinutes = todaysDate.getMinutes();
  todaysDate.setUTCHours(0,0,0,0);
  let today:string = todaysDate.toISOString();
  if(shiftStart === today){
    let time = value.split(":");
    let hour:number = parseInt(time[0]);
    let min:number = parseInt(time[1]);
    if(hour >= nowHours){ 
      if(hour === nowHours) {
        return min > nowMinutes
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return true
  }
} 


export {getShiftFormValidationSchema}