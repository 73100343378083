import * as React from 'react';
import { ShiftRowItem } from './ShiftRowItem';
import { useViewport } from '../../Hooks/viewport'
import { DateField } from './DateField';
import { isSafari, isIOS } from 'react-device-detect';
import { TimeField } from './TimeField';
import { ShiftField } from './ShiftField';
import { Field, useFormikContext } from 'formik';
import { RemoveShift } from './RemoveShift';
import { DateModal } from '../../MobileModals/DateModal';
import { TimeRangeModal } from '../../MobileModals/TimeRangeModal';
import { getValueFromArray } from '../../utils';

// this might need a tidy
export function ShiftRow(props:{
    index:number,
    isAltEnabled:boolean,
    errors:any,
    touched:any,
    remove:any, 
    scrollRef?,
    updateMinimumWage,
    minimumWageSet
    }) {
    // put this useful hook into its own file 
    const { width } = useViewport();
    let isFirstRow: boolean = props.index === 0; 
    let isMobile: boolean = width <= 500;
    let isTitleVisible: boolean = isFirstRow || isMobile;
    const {  values } = useFormikContext();


    // encapsulate into functions?
    let hasTouchedStartTime = (props.touched.shifts && props.touched.shifts[props.index] && props.touched.shifts[props.index].startTime) 
    let hasTouchedStartDate = (props.touched.shifts && props.touched.shifts[props.index] && props.touched.shifts[props.index].startDate) 
    let hasTouchedEndTime = (props.touched.shifts && props.touched.shifts[props.index] && props.touched.shifts[props.index].endTime) 

    let hasDateError: boolean  =  props.errors.shifts && props.errors.shifts[props.index] && props.errors.shifts[props.index].startDate;
    let hasStartTimeError: boolean = props.errors.shifts && props.errors.shifts[props.index] && props.errors.shifts[props.index].startTime;
    let hasEndTimeError: boolean = props.errors.shifts && props.errors.shifts[props.index] && props.errors.shifts[props.index].endTime;

    let showDateError: boolean = hasDateError && hasTouchedStartDate;
    let showStartTimeError: boolean = hasStartTimeError && hasTouchedStartTime;
    let showEndTimeError: boolean = hasEndTimeError && hasTouchedEndTime;


    let firstClass: string = isFirstRow ? "first-shift-row" :"";
    let startDatePlaceHolder:string = "dd/mm/yyyy";
    let startTimePlaceHolder:string = "HH:mm";
    let endTimePlaceHolder:string = "HH:mm";

    let timeRangeTitle:string = "Start - End time";
    let timeRangeTriggerField:string = `shifts.${props.index}.timeRangeTrigger`;
    let startTimeField:string = `shifts.${props.index}.startTime`;
    let endTimeField:string = `shifts.${props.index}.endTime`;
    let timeRange = <Field key={props.index} type="text" className="last-cell" placeHolder={timeRangeTitle} name={timeRangeTriggerField}/>

    let dateTitle:string = "Date";
    let dateTriggerField:string = `shifts.${props.index}.dateTrigger`;
    let dateField:string = `shifts.${props.index}.startDate`;
    let dateRange = <Field key={props.index} type="text" className="first-cell" placeHolder={dateTitle} name={dateTriggerField}/>
    let customDateModal = <DateModal children={<></>}/>
    let customTimeRangeModal = <TimeRangeModal index={props.index} children={<></>}/>


    let remove = () => {
        props.remove(props.index); 
    }

    return(
        <>
            <div className={`shift-input-group  shift-row-${props.index}`}>
                {!isMobile && (
                    <>
                        <ShiftRowItem 
                        fieldTitle={"Date"}
                        fieldName={dateField}
                        groupFieldName={"startDate"}
                        hasError={showDateError}
                        className={`${firstClass} first-shift-field `}
                        isTitleVisible={isTitleVisible}
                        isMobile={isMobile}
                        >                          
                                <DateField 
                                isAltFieldEnabled={props.isAltEnabled}
                                className={`${isSafari ? "ios-input-date":""} `}
                                safariTimeSelect={["shifts",`${props.index}`,"startDatepicker"]}
                                placeHolder={startDatePlaceHolder}
                                name={dateField}
                                updateMinimumWage={props.updateMinimumWage}
                                minimumWageSet={props.minimumWageSet}
                                />
                        </ShiftRowItem>
                        <ShiftRowItem 
                        fieldTitle={"Start time"}
                        fieldName={startTimeField}
                        groupFieldName={"startTime"}
                        hasError={showStartTimeError}
                        className={""}
                        isTitleVisible={isTitleVisible}
                        isMobile={isMobile}
                        >
                                <TimeField 
                                isAltFieldEnabled={props.isAltEnabled} 
                                className={`${isSafari ? "ios-input-time":""}`}  
                                placeHolder={startTimePlaceHolder}
                                safariTimeSelect={["shifts",`${props.index}`,"startTimepicker"]}
                                name={startTimeField} />
                        </ShiftRowItem>
                        <ShiftRowItem 
                        fieldTitle={"End time"}
                        fieldName={endTimeField}
                        groupFieldName={"endTime"}
                        hasError={showEndTimeError}
                        className={"final-cell"}
                        isTitleVisible={isTitleVisible}
                        isMobile={isMobile} 
                        >
                                <TimeField 
                                isAltFieldEnabled={props.isAltEnabled}
                                placeHolder={endTimePlaceHolder}
                                className={`${isSafari ? "ios-input-time":""}`}
                                safariTimeSelect={["shifts",`${props.index}`,"endTimepicker"]}
                                name={endTimeField} />
                        </ShiftRowItem>
                        <RemoveShift isFirstRow={isFirstRow} remove={remove}/>
                    </>
                )}
                {isMobile && (
                    //  can be simplified remove shift row item and replace with div wrapper alt fields not handled (a lot of room for improvement )
                    <>
                        <ShiftRowItem 
                        fieldTitle={dateTitle}
                        fieldName={dateField}
                        groupFieldName={"startDate"}
                        hasError={showDateError}
                        className={`${firstClass} first-shift-field overlay-container`}
                        isTitleVisible={isTitleVisible}
                        isMobile={isMobile}
                        >
                        <span className="desktop-hidden first-label-title">{ `${getValueFromArray(dateField,values) ? "Date":""}`}</span>
                            <ShiftField 
                            key={props.index}
                            fieldNames={[dateField]}
                            title={dateTitle}
                            isMobile={isMobile}
                            hiddenField={dateTriggerField}
                            customMobileFieldContent={dateRange}
                            customModalContent={customDateModal}
                            scrollRef={props.scrollRef}
                            >                     
                                <DateField 
                                isAltFieldEnabled={props.isAltEnabled}
                                className={`first-cell ${isIOS ? "ios-input-date":""} `}
                                safariTimeSelect={["shifts",`${props.index}`,"startDatepicker"]}
                                placeHolder={startDatePlaceHolder}
                                name={dateField}
                                isInline={true}
                                updateMinimumWage={props.updateMinimumWage}
                                minimumWageSet={props.minimumWageSet}
                                />
                            </ShiftField>
                        </ShiftRowItem>
                        <ShiftRowItem 
                            fieldTitle={timeRangeTitle}
                            fieldName={startTimeField}
                            groupFieldName={"startTime"}
                            hasError={showStartTimeError}
                            className={"overlay-container"}
                            isTitleVisible={isTitleVisible}
                            isMobile={isMobile}
                            >
                            <span className="desktop-hidden last-label-title">{ `${getValueFromArray(startTimeField,values) ? "Start - End time":""}`}</span>
                            <ShiftField 
                            key={props.index}
                            fieldNames={[startTimeField,endTimeField]}
                            title={timeRangeTitle}
                            isMobile={isMobile}
                            hiddenField={timeRangeTriggerField}
                            customMobileFieldContent={timeRange}
                            customModalContent={customTimeRangeModal}
                            scrollRef={props.scrollRef}
                            >
                                    <TimeField 
                                    isAltFieldEnabled={props.isAltEnabled} 
                                    className={`${isIOS ? "ios-input-time":""}`}  
                                    placeHolder={startTimePlaceHolder}
                                    safariTimeSelect={["shifts",`${props.index}`,"startTimepicker"]}
                                    name={startTimeField} />
                                    <TimeField 
                                    isAltFieldEnabled={props.isAltEnabled}
                                    placeHolder={endTimePlaceHolder}
                                    className={`mt2 ${isIOS ? "ios-input-time":""}`}
                                    safariTimeSelect={["shifts",`${props.index}`,"endTimepicker"]}
                                    name={endTimeField} />
                            </ShiftField>
                        </ShiftRowItem> 
                    </>
                )}
            </div>
        </>
    );

}