import { useField, Field } from "formik";
import React from 'react';

export const ScreeningQuestion = (props:{ index:number}) => {

  //  put dynamic placeholders
  let placeHolderList = [
    "Have you got your own transport?",
    "Are you comfortable with..?",
    "Do you have health and safety training..?"];

  return (
    <div
      className={"screening-question"}
    >
        <label>Question {props.index+1}</label>
        <Field
        name={`questions.${props.index}`}
        placeholder={placeHolderList[props.index]}
        type="text"
        />
        
    </div>
  );
};

