import React from 'react';
import {FieldArray, Formik, Form} from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {ScreeningQuestion} from './ScreeningQuestion';

import {faTrash} from '@fortawesome/free-solid-svg-icons';
import LinkedInTag from 'react-linkedin-insight';

export function Step5ScreeningQuestions(props: {oldValues: String[], onSubmit(values:String[]): Promise<void>, onSkip, backComponent?}) {
    
    let questionList: String[] = [""];
    if(props.oldValues.length > 0){
      questionList = props.oldValues;
    }
    
    return(
    <div className="hirer-onboarding-signup">    
      <div className="hirer-form-container">
      <span className="mini-modal">
          {props.backComponent}
         </span> 
        <div className="header-section">
          <h3 className="modal-section-title ">
            <strong>
              Add screening questions
            </strong>
          </h3>
          <p className="subheading">
              Use these questions to filter your applicants
          </p>
        </div> 

        <Formik
        initialValues={{questions:questionList}}
        validationSchema={Yup.object().shape({
          })}
        onSubmit={async (values, {setSubmitting}) => {
          let fullValues :String[] = [];
          values.questions.forEach(function(value){
            fullValues.push(value.toString())
          });
        try {
          LinkedInTag.init("2735777", 'dc', false);
          LinkedInTag.track("6144929");
          await props.onSubmit(fullValues);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({isSubmitting, errors, touched,values}) => (
        
        <Form className="hirer-onboarding-signup-form">
          <div className="text-left">

              <FieldArray 
              name="questions"
              render={({ insert, remove, push }) => (
                
                <div>
                  {values.questions.length > 0 && values.questions.map((question,index)=>(
                    <React.Fragment>
                      <ScreeningQuestion index={index} />
                      <div className="remove-item"
                            onClick={() => remove(index)}
                          >
                            <FontAwesomeIcon className="limber-blue-icon" icon={faTrash}/>
                      </div>
                    </React.Fragment>      
                  ))}
                  {values.questions.length < 3 && (
                  <React.Fragment>
                  <div  className="add-item"
                    onClick={() => add(values.questions.length-1,push(""))}
                  >
                    <span className="blue-pill">+</span> Add a question
                  </div>
                  </React.Fragment>
                  )}
                </div>

              )}
              />

          </div>

          <div className="mt1 text-center">
          {/* window.lintrk('track', { conversion_id: 6144929 }); */}
            <button className="add_screening_questions_tracking button button-primary" type="submit" disabled={isSubmitting}>
            ADD DETAILS  

              {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft: '1em'}}/>}
            </button>
          </div>
        </Form>
      )}
    </Formik>
    <div className="mt1 text-center">    
        <a className="button-tertiary" type="submit"  onClick={props.onSkip}>
            SKIP            
        </a>
    </div> 
    </div>
    </div>
    )
}

function add( index,pushHandler){
  if(index < 4){
    // console.log(index)
    pushHandler();
  }

}