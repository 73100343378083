import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";


export function Alert(props: {
  message: string;
}) {
  return <div className="alert">
    <div className="icon"><FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon></div>
    <div className="message">{props.message}</div>
  </div>
}
