
import * as React from 'react';
import { useFormikContext } from 'formik';

export function ShiftLabel(props:{fieldName:string ,title:string, currencyElement:string, titleClassName?:string, subTitleClassName?:string}) {
  // move to utils
  const {  values } = useFormikContext();
return(
    <>
        <span className={`${props.titleClassName} min-wage-label-title`}>{ props.title}</span>
        <span className={`${props.subTitleClassName} min-wage-label`}>{props.currencyElement}</span>
    </>
    )

}