import * as React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { getValueFromArray, isSameDay, getIdFromArray, isShortNotice } from '../../utils';
import { ValidationLabel } from './ValidationLabel';


export function ShiftRowItem(props:{children, fieldTitle:string, fieldName:string, groupFieldName:string, hasError:boolean, className?:string, isTitleVisible:boolean, isMobile:boolean}) {
    const {  values } = useFormikContext();
    // if same day (put a warn  ing)
    let hasShortNoticeWarning:boolean = isShortNotice(props.fieldName,values);;
    return(
        <div className={`input-group-cell ${props.className}  ${
            props.hasError ? 'validation-error' : ''
        }`} >
                {props.isTitleVisible && (
                <>
                    {!props.isMobile && (
                        <>
                            <div className="flex-error flex-error-start-date-shift">
                            <ValidationLabel hasShortNoticeWarning={hasShortNoticeWarning} fieldName={props.fieldName} />
                            </div>
                            <label className="field-label"  htmlFor={props.fieldName}>
                                {props.fieldTitle}
          
                            </label>
                        </>
                    )}

                </>
                )}
                {props.children}
                {props.isMobile && (
                    <ErrorMessage name={props.fieldName} component="div" className="badge-danger shift-badge mobile-shift-badge" />

                )}
                {!props.isTitleVisible && (
                    <>
                    <ErrorMessage name={props.fieldName} component="div" className="badge-danger shift-badge" />
                    {hasShortNoticeWarning && (
                        <>
                            <ValidationLabel hasShortNoticeWarning={hasShortNoticeWarning} fieldName={props.fieldName} />
                        </>
                    )}
                    </>
                    
                )}
        </div>
    )
}