
import * as React from 'react';
import {  ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ModalFieldWrapper } from '../ModalFieldWrapper';
import { WarningModal } from '../../DesktopModals/WarningModal';
import { useState } from 'react';

export function ValidationLabel(props:{fieldName, hasShortNoticeWarning, className?:string}) {
  // move to utils
  const [isModalOpenState, setIsModalOpenState] = useState<boolean>(false); 

return(
    <>
    <ErrorMessage name={props.fieldName} component="div" className={`badge badge-danger `} />
        {/* can be its own component */}
        {props.hasShortNoticeWarning && (
            <>
                <div className="shift-badge badge-warning"  onClick={() => {onOpenModal()}}>
                    <FontAwesomeIcon icon={faExclamationTriangle} onMouseOver={() => {}} /> Short notice warning
                </div>
                <ModalFieldWrapper 
                isOpen={isModalOpenState}
                setIsOpen={setIsModalOpenState} >
                    <WarningModal/>
                </ModalFieldWrapper>
            </>
        )}
    </>
    )

    function onOpenModal(){
        setIsModalOpenState(!isModalOpenState)
    }

}